import {
  PRODUCTS_QUERY_REQUESTED,
  PRODUCTS_QUERY_RETRIEVED,
  PRODUCTS_QUERY_FAILED,
  USER_PRODUCTS_REQUESTED,
  USER_PRODUCT_RETRIEVED,
  USER_PRODUCT_FAILED,
  PUBLISHING_PRODUCT_REQUESTED,
  PUBLISHING_PRODUCT_SUCCESSFULL,
  PUBLISHING_PRODUCT_FAILED,
  PRODUCT_DETAIL_REQUESTED,
  PRODUCT_DETAIL_RETRIEVED,
  PRODUCT_DETAIL_FAILED,
  PRODUCTS_LATEST_REQUESTED,
  PRODUCTS_LATEST_SUCCESSFULL,
  PRODUCTS_LATEST_ERRORED,
  PRODUCTS_DISCOUNT_REQUESTED,
  PRODUCTS_DISCOUNT_SUCCESSFULL,
  PRODUCTS_DISCOUNT_ERRORED,
  PRODUCTS_POPULAR_REQUESTED,
  PRODUCTS_POPULAR_SUCCESSFULL,
  PRODUCTS_POPULAR_ERRORED,
  PRODUCTS_ROLE_REQUESTED,
  PRODUCTS_ROLE_RETRIEVED,
  PRODUCTS_ROLE_FAILED,
  PRODUCTS_SIZES_REQUESTED,
  PRODUCTS_SIZES_RETRIEVED,
  PRODUCTS_SIZES_FAILED,
  PRODUCTS_FAVOURITE_BRANDS_REQUESTED,
  PRODUCTS_FAVOURITE_BRANDS_RETRIEVED,
  PRODUCTS_FAVOURITE_BRANDS_FAILED,
  PRODUCTS_CATEGORY_REQUESTED,
  PRODUCTS_CATEGORY_RETRIEVED,
  PRODUCTS_CATEGORY_FAILED,
  PRODUCTS_FAVOURITES_REQUESTED,
  PRODUCTS_FAVOURITES_RETRIEVED,
  PRODUCTS_FAVOURITES_FAILED,
  PRODUCTS_SUGGESTED_DRESSERS_REQUESTED,
  PRODUCTS_SUGGESTED_DRESSERS_RETRIEVED,
  PRODUCTS_SUGGESTED_DRESSERS_FAILED,
  PRODUCTS_FOLLOWINGS_REQUESTED,
  PRODUCTS_FOLLOWINGS_RETRIEVED,
  PRODUCTS_FOLLOWINGS_FAILED,
  PRODUCTS_BRAND_REQUESTED,
  PRODUCTS_BRAND_RETRIEVED,
  PRODUCTS_BRAND_FAILED,
  REPUBLISH_REQUESTED,
  REPUBLISH_SUCCESSFULL,
  REPUBLISH_FAILED,
  SEND_OFFER_REQUESTED,
  SEND_OFFER_SUCCESSFULL,
  SEND_OFFER_ERRORED,
  MY_PRODUCTS_REQUESTED,
  MY_PRODUCTS_RETRIEVED,
  MY_PRODUCTS_FAILED,
  OFFER_PRODUCTS_REQUESTED,
  OFFER_PRODUCTS_RETRIEVED,
  OFFER_PRODUCTS_FAILED,
  PAUSE_PRODUCT_REQUESTED,
  PAUSE_PRODUCT_SUCCESSFULL,
  PAUSE_PRODUCT_ERRORED,
  RESUME_PRODUCT_REQUESTED,
  RESUME_PRODUCT_SUCCESSFULL,
  RESUME_PRODUCT_ERRORED,
  CHANGE_PRODUCT_PRICE_REQUESTED,
  CHANGE_PRODUCT_PRICE_SUCCESSFULL,
  CHANGE_PRODUCT_PRICE_ERRORED,
  CHANGE_PRODUCT_STOCK_REQUESTED,
  CHANGE_PRODUCT_STOCK_SUCCESSFULL,
  CHANGE_PRODUCT_STOCK_ERRORED,
  DELETE_PRODUCT_REQUESTED,
  DELETE_PRODUCT_SUCCESSFULL,
  DELETE_PRODUCT_ERRORED,
  ACCEPT_OFFER_REQUESTED,
  ACCEPT_OFFER_SUCCESSFULL,
  ACCEPT_OFFER_ERRORED,
  REJECT_OFFER_REQUESTED,
  REJECT_OFFER_SUCCESSFULL,
  REJECT_OFFER_ERRORED,
  COUNTER_OFFER_REQUESTED,
  COUNTER_OFFER_SUCCESSFULL,
  COUNTER_OFFER_ERRORED,
  DROP_NOTIFICATION_REQUESTED,
  DROP_NOTIFICATION_SUCCESSFULL,
  DROP_NOTIFICATION_ERRORED,
  UPDATE_PRODUCT_REQUESTED,
  UPDATE_PRODUCT_SUCCESSFULL,
  UPDATE_PRODUCT_ERRORED,
  UPDATE_PRODUCT_IMAGES_SUCCESSFULL,
  UPDATE_PRODUCT_IMAGES_ERRORED,
  REPUBLISH_OWNED_REQUESTED,
  REPUBLISH_OWNED_SUCCESSFUL,
  REPUBLISH_OWNED_FAILED
} from '../actions/types';

const defaultState = {
  productsFromUser: { user: '', products: [] },
  latestProducts: [],
  discountProducts: [],
  popularProducts: [],
  sizesProducts: [],
  favouriteProducts: [],
  favouriteBrandsProducts: [],
  suggestedDressersProducts: [],
  followingsProducts: [],
  brandProducts: {},
  roleProducts: {
    roleRequesting: null,
    ROLE_SHOWROOM: [],
    ROLE_PREMIUM: [],
    ROLE_CELEBRITY: []
  },
  categoryProducts: {},
  products: [],
  productsFacets: {},
  lastProductsTimestamp: null,

  product: null,
  loading: false,

  status: null,
  error: null,

  republishProduct: null,
  republish: null,
  offer: null,

  currentProduct: null,

  myProducts: [],
  offerProducts: []
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case PRODUCTS_QUERY_REQUESTED: {
      return {
        ...state,
        status: PRODUCTS_QUERY_REQUESTED,
        lastProductsTimestamp: action.timestamp
      };
    }
    case PRODUCTS_QUERY_RETRIEVED: {
      if (state.lastProductsTimestamp !== action.timestamp) {
        return state;
      }
      return {
        ...state,
        status: PRODUCTS_QUERY_RETRIEVED,
        products: action.products,
        productsFacets: action.facets
      };
    }
    case PRODUCTS_QUERY_FAILED: {
      if (state.lastProductsTimestamp !== action.timestamp) {
        return state;
      }
      return {
        ...state,
        status: PRODUCTS_QUERY_FAILED,
        error: action.error
      };
    }
    case PUBLISHING_PRODUCT_REQUESTED: {
      return {
        ...state,
        status: PUBLISHING_PRODUCT_REQUESTED,
        loading: true
      };
    }
    case PUBLISHING_PRODUCT_SUCCESSFULL: {
      return {
        ...state,
        status: PUBLISHING_PRODUCT_SUCCESSFULL,
        loading: false
      };
    }
    case PUBLISHING_PRODUCT_FAILED: {
      return {
        ...state,
        status: PUBLISHING_PRODUCT_FAILED,
        error: action.error,
        loading: false
      };
    }
    case USER_PRODUCTS_REQUESTED: {
      return {
        ...state,
        status: USER_PRODUCTS_REQUESTED,
        productsFromUser: {
          user: '',
          products: []
        }
      };
    }
    case USER_PRODUCT_RETRIEVED: {
      return {
        ...state,
        status: USER_PRODUCT_RETRIEVED,
        productsFromUser: {
          user: action.user,
          products: action.products
        }
      };
    }
    case USER_PRODUCT_FAILED: {
      return {
        ...state,
        status: USER_PRODUCT_FAILED,
        productsFromUser: {
          user: action.user,
          products: []
        }
      };
    }
    case PRODUCTS_LATEST_REQUESTED: {
      return {
        ...state,
        status: PRODUCTS_LATEST_REQUESTED
      };
    }
    case PRODUCTS_LATEST_SUCCESSFULL: {
      return {
        ...state,
        status: PRODUCTS_LATEST_SUCCESSFULL,
        latestProducts: action.products
      };
    }
    case PRODUCTS_LATEST_ERRORED: {
      return {
        ...state,
        status: PRODUCTS_LATEST_ERRORED
      };
    }
    case PRODUCTS_DISCOUNT_REQUESTED: {
      return {
        ...state,
        status: PRODUCTS_DISCOUNT_REQUESTED
      };
    }
    case PRODUCTS_DISCOUNT_SUCCESSFULL: {
      return {
        ...state,
        status: PRODUCTS_DISCOUNT_SUCCESSFULL,
        discountProducts: action.products
      };
    }
    case PRODUCTS_DISCOUNT_ERRORED: {
      return {
        ...state,
        status: PRODUCTS_DISCOUNT_ERRORED
      };
    }
    case PRODUCTS_POPULAR_REQUESTED: {
      return {
        ...state,
        status: PRODUCTS_POPULAR_REQUESTED
      };
    }
    case PRODUCTS_POPULAR_SUCCESSFULL: {
      return {
        ...state,
        status: PRODUCTS_POPULAR_SUCCESSFULL,
        popularProducts: action.products
      };
    }
    case PRODUCTS_POPULAR_ERRORED: {
      return {
        ...state,
        status: PRODUCTS_POPULAR_ERRORED
      };
    }
    case PRODUCT_DETAIL_REQUESTED: {
      return {
        ...state,
        status: PRODUCT_DETAIL_REQUESTED,
        product: null
      };
    }
    case PRODUCT_DETAIL_RETRIEVED: {
      return {
        ...state,
        status: PRODUCT_DETAIL_RETRIEVED,
        product: action.product
      };
    }
    case PRODUCT_DETAIL_FAILED: {
      return {
        ...state,
        status: PRODUCT_DETAIL_FAILED,
        error: action.error
      };
    }
    case PRODUCTS_ROLE_REQUESTED: {
      return {
        ...state,
        status: PRODUCTS_ROLE_REQUESTED,
        roleProducts: {
          roleRequesting: action.role,
          ...state.roleProducts
        }
      };
    }
    case PRODUCTS_ROLE_RETRIEVED: {
      return {
        ...state,
        status: PRODUCTS_ROLE_RETRIEVED,
        roleProducts: {
          ...state.roleProducts,
          roleRequesting: action.role,
          [action.role]: action.products
        }
      };
    }
    case PRODUCTS_ROLE_FAILED: {
      return {
        ...state,
        status: PRODUCTS_ROLE_FAILED,
        roleProducts: {
          ...state.roleProducts,
          roleRequesting: action.role
        }
      };
    }
    case PRODUCTS_CATEGORY_REQUESTED: {
      return {
        ...state,
        status: PRODUCTS_CATEGORY_REQUESTED,
        categoryProducts: {
          ...state.categoryProducts,
          currentCategory: action.categoryID
        }
      };
    }
    case PRODUCTS_CATEGORY_RETRIEVED: {
      return {
        ...state,
        status: PRODUCTS_CATEGORY_RETRIEVED,
        categoryProducts: {
          ...state.categoryProducts,
          currentCategory: action.categoryID,
          [action.categoryID]: action.products
        }
      };
    }
    case PRODUCTS_CATEGORY_FAILED: {
      return {
        ...state,
        status: PRODUCTS_CATEGORY_FAILED,
        error: action.error,
        categoryProducts: {
          ...state.categoryProducts,
          currentCategory: action.categoryID
        }
      };
    }
    case PRODUCTS_SIZES_REQUESTED: {
      return {
        ...state,
        status: PRODUCTS_SIZES_REQUESTED
      };
    }
    case PRODUCTS_SIZES_RETRIEVED: {
      return {
        ...state,
        sizesProducts: action.products,
        status: PRODUCTS_SIZES_RETRIEVED
      };
    }
    case PRODUCTS_SIZES_FAILED: {
      return {
        ...state,
        status: PRODUCTS_SIZES_FAILED
      };
    }
    case PRODUCTS_FAVOURITE_BRANDS_REQUESTED: {
      return {
        ...state,
        status: PRODUCTS_FAVOURITE_BRANDS_REQUESTED
      };
    }
    case PRODUCTS_FAVOURITE_BRANDS_RETRIEVED: {
      return {
        ...state,
        favouriteBrandsProducts: action.products,
        status: PRODUCTS_FAVOURITE_BRANDS_RETRIEVED
      };
    }
    case PRODUCTS_FAVOURITE_BRANDS_FAILED: {
      return {
        ...state,
        status: PRODUCTS_FAVOURITE_BRANDS_FAILED
      };
    }
    case PRODUCTS_FAVOURITES_REQUESTED: {
      return {
        ...state,
        status: PRODUCTS_FAVOURITES_REQUESTED
      };
    }
    case PRODUCTS_FAVOURITES_RETRIEVED: {
      return {
        ...state,
        status: PRODUCTS_FAVOURITES_RETRIEVED,
        favouriteProducts: action.products
      };
    }
    case PRODUCTS_FAVOURITES_FAILED: {
      return {
        ...state,
        status: PRODUCTS_FAVOURITES_FAILED
      };
    }
    case PRODUCTS_SUGGESTED_DRESSERS_REQUESTED: {
      return {
        ...state,
        status: PRODUCTS_SUGGESTED_DRESSERS_REQUESTED
      };
    }
    case PRODUCTS_SUGGESTED_DRESSERS_RETRIEVED: {
      return {
        ...state,
        status: PRODUCTS_SUGGESTED_DRESSERS_RETRIEVED,
        suggestedDressersProducts: action.products
      };
    }
    case PRODUCTS_SUGGESTED_DRESSERS_FAILED: {
      return {
        ...state,
        status: PRODUCTS_SUGGESTED_DRESSERS_FAILED
      };
    }
    case PRODUCTS_FOLLOWINGS_REQUESTED: {
      return {
        ...state,
        status: PRODUCTS_FOLLOWINGS_REQUESTED
      };
    }
    case PRODUCTS_FOLLOWINGS_RETRIEVED: {
      return {
        ...state,
        status: PRODUCTS_FOLLOWINGS_RETRIEVED,
        followingsProducts: action.products
      };
    }
    case PRODUCTS_FOLLOWINGS_FAILED: {
      return {
        ...state,
        status: PRODUCTS_FOLLOWINGS_FAILED
      };
    }
    case PRODUCTS_BRAND_REQUESTED: {
      return {
        ...state,
        status: PRODUCTS_BRAND_REQUESTED,
        brandProducts: {
          ...state.brandProducts,
          [action.brand]: {
            products: [],
            requesting: true
          }
        }
      };
    }
    case PRODUCTS_BRAND_RETRIEVED: {
      return {
        ...state,
        status: PRODUCTS_BRAND_RETRIEVED,
        brandProducts: {
          ...state.brandProducts,
          [action.brand]: {
            requesting: false,
            products: action.products
          }
        }
      };
    }
    case PRODUCTS_BRAND_FAILED: {
      return {
        ...state,
        status: PRODUCTS_BRAND_FAILED,
        brandProducts: {
          ...state.brandProducts,
          [action.brand]: {
            requesting: false,
            products: []
          }
        }
      };
    }
    case REPUBLISH_REQUESTED: {
      return {
        ...state,
        republishProduct: action.republishProduct,
        status: REPUBLISH_REQUESTED
      };
    }
    case REPUBLISH_SUCCESSFULL: {
      return {
        ...state,
        republishProduct: action.republishProduct,
        status: REPUBLISH_SUCCESSFULL,
        republish: action.republish
      };
    }
    case REPUBLISH_FAILED: {
      return {
        ...state,
        republishProduct: action.republishProduct,
        status: REPUBLISH_FAILED,
        error: action.error
      };
    }
    case REPUBLISH_OWNED_REQUESTED: {
      return {
        ...state,
        republishProduct: action.republishProductId,
        status: REPUBLISH_OWNED_REQUESTED
      };
    }
    case REPUBLISH_OWNED_SUCCESSFUL: {
      return {
        ...state,
        republishProduct: action.republishProductId,
        status: REPUBLISH_OWNED_SUCCESSFUL,
        republish: action.republishedProduct
      };
    }
    case REPUBLISH_OWNED_FAILED: {
      return {
        ...state,
        republishProduct: action.republishProductId,
        status: REPUBLISH_OWNED_FAILED,
        error: action.error
      };
    }
    case SEND_OFFER_REQUESTED: {
      return {
        ...state,
        status: SEND_OFFER_REQUESTED
      };
    }
    case SEND_OFFER_SUCCESSFULL: {
      return {
        ...state,
        status: SEND_OFFER_SUCCESSFULL,
        offer: action.offer
      };
    }
    case SEND_OFFER_ERRORED: {
      return {
        ...state,
        status: SEND_OFFER_ERRORED,
        error: action.error
      };
    }
    case MY_PRODUCTS_REQUESTED: {
      return {
        ...state,
        status: MY_PRODUCTS_REQUESTED
      };
    }
    case MY_PRODUCTS_RETRIEVED: {
      return {
        ...state,
        status: MY_PRODUCTS_RETRIEVED,
        myProducts: action.products
      };
    }
    case MY_PRODUCTS_FAILED: {
      return {
        ...state,
        status: MY_PRODUCTS_FAILED,
        error: action.error
      };
    }
    case OFFER_PRODUCTS_REQUESTED: {
      return {
        ...state,
        status: OFFER_PRODUCTS_REQUESTED
      };
    }
    case OFFER_PRODUCTS_RETRIEVED: {
      return {
        ...state,
        status: OFFER_PRODUCTS_RETRIEVED,
        offerProducts: action.products
      };
    }
    case OFFER_PRODUCTS_FAILED: {
      return {
        ...state,
        status: OFFER_PRODUCTS_FAILED,
        error: action.error
      };
    }
    case PAUSE_PRODUCT_REQUESTED: {
      return {
        ...state,
        status: PAUSE_PRODUCT_REQUESTED,
        currentProduct: action.product
      };
    }
    case PAUSE_PRODUCT_SUCCESSFULL: {
      return {
        ...state,
        status: PAUSE_PRODUCT_SUCCESSFULL,
        currentProduct: action.product.id ? action.product.id : action.product,
        myProducts: state.myProducts.filter(p => p.id !== action.product.id),
        offerProducts: state.offerProducts.filter(
          p => p.id !== action.product.id
        )
      };
    }
    case PAUSE_PRODUCT_ERRORED: {
      return {
        ...state,
        status: PAUSE_PRODUCT_ERRORED,
        error: action.error,
        currentProduct: action.product
      };
    }
    case RESUME_PRODUCT_REQUESTED: {
      return {
        ...state,
        status: RESUME_PRODUCT_REQUESTED,
        currentProduct: action.product
      };
    }
    case RESUME_PRODUCT_SUCCESSFULL: {
      return {
        ...state,
        status: RESUME_PRODUCT_SUCCESSFULL,
        currentProduct: action.product.id ? action.product.id : action.product,
        myProducts: state.myProducts.filter(p => p.id !== action.product.id),
        offerProducts: state.offerProducts.filter(
          p => p.id !== action.product.id
        )
      };
    }
    case RESUME_PRODUCT_ERRORED: {
      return {
        ...state,
        status: RESUME_PRODUCT_ERRORED,
        error: action.error,
        currentProduct: action.product
      };
    }
    case CHANGE_PRODUCT_PRICE_REQUESTED: {
      return {
        ...state,
        status: CHANGE_PRODUCT_PRICE_REQUESTED,
        currentProduct: action.product
      };
    }
    case CHANGE_PRODUCT_PRICE_SUCCESSFULL: {
      const products = [...state.myProducts];
      const index = products.findIndex(p => p.id === action.product.id);
      if (index !== -1) {
        products[index] = action.product;
      }
      return {
        ...state,
        status: CHANGE_PRODUCT_PRICE_SUCCESSFULL,
        currentProduct: action.product.id ? action.product.id : action.product,
        myProducts: products
      };
    }
    case CHANGE_PRODUCT_PRICE_ERRORED: {
      return {
        ...state,
        status: CHANGE_PRODUCT_PRICE_ERRORED,
        error: action.error,
        currentProduct: action.product
      };
    }
    case CHANGE_PRODUCT_STOCK_REQUESTED: {
      return {
        ...state,
        status: CHANGE_PRODUCT_STOCK_REQUESTED,
        currentProduct: action.product
      };
    }
    case CHANGE_PRODUCT_STOCK_SUCCESSFULL: {
      return {
        ...state,
        status: CHANGE_PRODUCT_STOCK_SUCCESSFULL,
        currentProduct: action.product.id ? action.product.id : action.product
      };
    }
    case CHANGE_PRODUCT_STOCK_ERRORED: {
      return {
        ...state,
        status: CHANGE_PRODUCT_STOCK_ERRORED,
        error: action.error,
        currentProduct: action.product
      };
    }
    case DELETE_PRODUCT_REQUESTED: {
      return {
        ...state,
        status: DELETE_PRODUCT_REQUESTED,
        currentProduct: action.product
      };
    }
    case DELETE_PRODUCT_SUCCESSFULL: {
      return {
        ...state,
        status: DELETE_PRODUCT_SUCCESSFULL,
        currentProduct: action.product,
        myProducts: state.myProducts.filter(p => p.id !== action.product.id),
        offerProducts: state.offerProducts.filter(
          p => p.id !== action.product.id
        )
      };
    }
    case DELETE_PRODUCT_ERRORED: {
      return {
        ...state,
        status: DELETE_PRODUCT_ERRORED,
        error: action.error,
        currentProduct: action.product
      };
    }
    case ACCEPT_OFFER_REQUESTED: {
      return {
        ...state,
        status: ACCEPT_OFFER_REQUESTED,
        currentProduct: action.product
      };
    }
    case ACCEPT_OFFER_SUCCESSFULL: {
      const products = [...state.myProducts];
      const index = products.findIndex(p => p.id === action.product.id);
      if (index !== -1) {
        products[index] = action.product;
      }

      return {
        ...state,
        status: ACCEPT_OFFER_SUCCESSFULL,
        currentProduct: action.product.id ? action.product.id : action.product,
        offerProducts: state.offerProducts.filter(
          p => p.id !== action.product.id
        ),
        myProducts: products
      };
    }
    case ACCEPT_OFFER_ERRORED: {
      return {
        ...state,
        status: ACCEPT_OFFER_ERRORED,
        error: action.error,
        currentProduct: action.product
      };
    }
    case REJECT_OFFER_REQUESTED: {
      return {
        ...state,
        status: REJECT_OFFER_REQUESTED,
        currentProduct: action.product
      };
    }
    case REJECT_OFFER_SUCCESSFULL: {
      const products = [...state.myProducts];
      const index = products.findIndex(p => p.id === action.product.id);
      if (index !== -1) {
        products[index] = action.product;
      }

      return {
        ...state,
        status: REJECT_OFFER_SUCCESSFULL,
        currentProduct: action.product.id ? action.product.id : action.product,
        offerProducts: state.offerProducts.filter(
          p => p.id !== action.product.id
        ),
        myProducts: products
      };
    }
    case REJECT_OFFER_ERRORED: {
      return {
        ...state,
        status: REJECT_OFFER_ERRORED,
        error: action.error,
        currentProduct: action.product
      };
    }
    case COUNTER_OFFER_REQUESTED: {
      return {
        ...state,
        status: COUNTER_OFFER_REQUESTED,
        currentProduct: action.product
      };
    }
    case COUNTER_OFFER_SUCCESSFULL: {
      const products = [...state.myProducts];
      const index = products.findIndex(p => p.id === action.product.id);
      if (index !== -1) {
        products[index] = action.product;
      }

      return {
        ...state,
        status: COUNTER_OFFER_SUCCESSFULL,
        currentProduct: action.product.id ? action.product.id : action.product,
        offerProducts: state.offerProducts.filter(
          p => p.id !== action.product.id
        ),
        myProducts: products
      };
    }
    case COUNTER_OFFER_ERRORED: {
      return {
        ...state,
        status: COUNTER_OFFER_ERRORED,
        error: action.error,
        currentProduct: action.product
      };
    }
    case DROP_NOTIFICATION_REQUESTED: {
      return {
        ...state,
        status: DROP_NOTIFICATION_REQUESTED
      };
    }
    case DROP_NOTIFICATION_SUCCESSFULL: {
      return {
        ...state,
        status: DROP_NOTIFICATION_SUCCESSFULL,
        product: {
          ...state.product,
          waiting_on_reservation_drop: [
            ...state.product.waiting_on_reservation_drop,
            action.user
          ]
        }
      };
    }
    case DROP_NOTIFICATION_ERRORED: {
      return {
        ...state,
        status: DROP_NOTIFICATION_ERRORED
      };
    }
    case UPDATE_PRODUCT_REQUESTED: {
      return {
        ...state,
        status: UPDATE_PRODUCT_REQUESTED
      };
    }
    case UPDATE_PRODUCT_SUCCESSFULL: {
      return {
        ...state,
        status: UPDATE_PRODUCT_SUCCESSFULL
      };
    }
    case UPDATE_PRODUCT_ERRORED: {
      return {
        ...state,
        status: UPDATE_PRODUCT_ERRORED,
        error: action.error
      };
    }
    case UPDATE_PRODUCT_IMAGES_SUCCESSFULL: {
      return {
        ...state,
        status: UPDATE_PRODUCT_IMAGES_SUCCESSFULL
      };
    }
    case UPDATE_PRODUCT_IMAGES_ERRORED: {
      return {
        ...state,
        status: UPDATE_PRODUCT_IMAGES_ERRORED,
        error: action.error
      };
    }
    default:
      return state;
  }
};
