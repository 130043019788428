import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'es-AR',
  lng: `es-${process.env.REACT_APP_COUNTRY}`,
  resources: {
    'es-AR': {
      translations: require('./locales/es-AR/translations.json'),
      home: require('./locales/es-AR/home.json'),
      howItWorks: require('./locales/es-AR/howItWorks.json'),
      products: require('./locales/es-AR/products.json'),
      myAccount: require('./locales/es-AR/myAccount.json'),
      sales: require('./locales/es-AR/sales.json'),
      messages: require('./locales/es-AR/messages.json'),
      myPromotions: require('./locales/es-AR/myPromotions.json'),
      sell: require('./locales/es-AR/sell.json'),
      checkout: require('./locales/es-AR/checkout.json'),
      dresser: require('./locales/es-AR/dresser.json'),
      purchases: require('./locales/es-AR/purchases.json'),
      myOffers: require('./locales/es-AR/myOffers.json'),
      myAlerts: require('./locales/es-AR/myAlerts.json'),
      social: require('./locales/es-AR/social.json'),
      join: require('./locales/es-AR/join.json'),
      store: require('./locales/es-AR/store.json'),
      profileConfiguration: require('./locales/es-AR/profileConfiguration.json'),
      myProducts: require('./locales/es-AR/myProducts.json'),
      shop: require('./locales/es-AR/shop.json'),
      dressers: require('./locales/es-AR/dressers.json'),
      shippingLabel: require('./locales/es-AR/shippingLabel.json')
    },
    'es-CL': {
      translations: require('./locales/es-CL/translations.json'),
      home: require('./locales/es-CL/home.json'),
      howItWorks: require('./locales/es-CL/howItWorks.json'),
      products: require('./locales/es-CL/products.json'),
      myAccount: require('./locales/es-CL/myAccount.json'),
      sales: require('./locales/es-CL/sales.json'),
      messages: require('./locales/es-CL/messages.json'),
      myPromotions: require('./locales/es-CL/myPromotions.json'),
      sell: require('./locales/es-CL/sell.json'),
      checkout: require('./locales/es-CL/checkout.json'),
      dresser: require('./locales/es-CL/dresser.json'),
      purchases: require('./locales/es-CL/purchases.json'),
      myOffers: require('./locales/es-CL/myOffers.json'),
      myAlerts: require('./locales/es-CL/myAlerts.json'),
      social: require('./locales/es-CL/social.json'),
      join: require('./locales/es-CL/join.json'),
      store: require('./locales/es-CL/store.json'),
      profileConfiguration: require('./locales/es-CL/profileConfiguration.json'),
      myProducts: require('./locales/es-CL/myProducts.json'),
      shop: require('./locales/es-CL/shop.json'),
      dressers: require('./locales/es-CL/dressers.json'),
      shippingLabel: require('./locales/es-CL/shippingLabel.json')
    }
  },
  ns: [
    'translations',
    'checkout',
    'dresser',
    'dressers',
    'help',
    'home',
    'howItWorks',
    'join',
    'messages',
    'mobileLoading',
    'myAccount',
    'myAlerts',
    'myFavourites',
    'myOffers',
    'myProducts',
    'myPromotions',
    'notifications',
    'products',
    'profileConfiguration',
    'purchases',
    'sales',
    'sell',
    'shippingLabel',
    'shop',
    'social',
    'store'
  ],
  interpolation: {
    escapeValue: false,
    format: function (value, format, _lng) {
      switch (format) {
        case 'uppercase':
          return value.toUpperCase();
        case 'lowercase':
          return value.toLowerCase();
        case 'capitalize':
          return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
        default:
          return value;
      }
    }
  },
  defaultNS: 'translations'
});

i18n.languages = ['es-AR', 'es-CL'];

export default i18n;
