import AbstractRestClient from '../AbstractRestClient';
import store from '../../redux/store';

class UsersRestClient extends AbstractRestClient {
  getUser = () => store.getState().auth.user.id;

  getCart() {
    const user = this.getUser();
    return this.instance.get(`/api/es_ar/users/${user}/shopping/cart`);
  }

  addToCart(product) {
    const user = this.getUser();
    return this.instance.post(
      `/api/es_ar/users/${user}/shoppings/${product}/carts`
    );
  }

  removeFromCart(product) {
    const user = this.getUser();
    return this.instance.delete(
      `/api/es_ar/users/${user}/shoppings/${product}/cart`
    );
  }

  changeEmail(email) {
    const user = this.getUser();
    return this.instance.patch(`/api/es_ar/users/${user}/email`, { email });
  }

  changePhone(phone) {
    const user = this.getUser();
    return this.instance.patch(`/api/es_ar/users/${user}/phone`, { phone });
  }

  verifyPin(pin) {
    const user = this.getUser();
    return this.instance.post(`/api/es_ar/users/${user}/pins`, { pin });
  }

  updateUser(updatedUser) {
    const user = this.getUser();
    return this.instance.patch(`/api/es_ar/users/${user}`, updatedUser);
  }

  updatePassword(password) {
    const user = this.getUser();
    return this.instance.patch(`/api/es_ar/users/${user}/password`, {
      password
    });
  }

  fetchRecommends() {
    const user = this.getUser();
    return this.instance.get(`/api/es_ar/users/${user}/recommend`);
  }

  updateMedia(image) {
    const user = this.getUser();
    const formBody = new FormData();
    formBody.append('media', image, 'image.jpg');
    return this.instance.post(`/api/es_ar/users/${user}/media`, formBody, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  fetchUser(id, idType = 'id') {
    if (idType === 'username') {
      return this.instance.get(`/api/es_ar/users/${id}/username`);
    }
    return this.instance.get(`/api/es_ar/users/${id}`);
  }

  fetchPrivateStats() {
    const user = this.getUser();
    return this.instance.get(`/api/es_ar/users/${user}/stats/private`);
  }

  fetchPublicStats(user) {
    return this.instance.get(`/api/es_ar/users/${user}/stats/public`);
  }

  getFavourites(user, offset = 0, pageSize = 10) {
    return this.instance.get(
      `/api/es_ar/users/${user}/favourite?offset=${offset}&pageSize=${pageSize}`
    );
  }

  fetchReviews(user, page, pageSize) {
    return this.instance.get(
      `/api/es_ar/users/${user}/reviews/received?page=${page}&pageSize=${pageSize}`
    );
  }

  fetchAddresses(seller) {
    const user = this.getUser();
    return this.instance.get(`/api/es_ar/users/${user}/shippings/${seller}`);
  }

  createShippingAddress(address) {
    const user = this.getUser();
    return this.instance.post(
      `/api/es_ar/users/${user}/shippings/addresses`,
      address
    );
  }

  createShippingStore(shippingStore, isChange = false) {
    const user = this.getUser();
    return this.instance.post(`/api/es_ar/users/${user}/shippings/stores`, {
      ...shippingStore,
      isChange
    });
  }

  deleteShippingAddress(address) {
    const user = this.getUser();
    return this.instance.delete(
      `/api/es_ar/users/${user}/shippings/${address}`
    );
  }

  getDressers(params, page = 1, pageSize = 10) {
    return this.instance.get(
      `/api/es_ar/users?page=${page}&pageSize=${pageSize}`,
      { params }
    );
  }

  activateHolidayMode() {
    const user = this.getUser();

    return this.instance.post(`/api/es_ar/users/${user}/vacations/enables`);
  }

  deactivateHolidayMode() {
    const user = this.getUser();

    return this.instance.post(
      `/api/es_ar/users/${user}/vacations/enables/disables`
    );
  }

  enableUser() {
    const user = this.getUser();

    return this.instance.post(`/api/es_ar/users/${user}/enables`);
  }

  disableUser() {
    const user = this.getUser();

    return this.instance.post(`/api/es_ar/users/${user}/disables`);
  }

  startPromotion(promotion) {
    const requestObject = {
      ...promotion,
      user: this.getUser()
    };
    return this.instance.post('/api/es_ar/promotions', requestObject);
  }

  deletePromotion(promotion) {
    return this.instance.delete(`/api/es_ar/promotions/${promotion}`);
  }

  createReview(order, review, stars) {
    return this.instance.post(`/api/es_ar/orders/${order}/reviews`, {
      review,
      stars
    });
  }

  answerReview(order, review, answer) {
    return this.instance.post(
      `/api/es_ar/orders/${order}/reviews/${review}/answers`,
      {
        answer
      }
    );
  }

  calculateDiscount(discount) {
    const user = this.getUser();

    return this.instance.post(`/api/es_ar/users/${user}/discounts`, discount);
  }

  getCampaigns(products) {
    const user = this.getUser();

    return this.instance.post(`/api/es_ar/users/${user}/discounts/campaigns`, {
      products
    });
  }

  getCoupons() {
    const user = this.getUser();

    return this.instance.get(`/api/es_ar/users/${user}/coupons`);
  }

  postUTM(utm) {
    const user = this.getUser();

    return this.instance.post(`/api/es_ar/users/${user}/utms`, utm);
  }

  getBuyerOffers(page = 1, pageSize = 10) {
    return this.instance.get(
      `/api/es_ar/offers/buyer?page=${page}&pageSize=${pageSize}`
    );
  }

  getAlerts() {
    const user = this.getUser();

    return this.instance.get(`/api/es_ar/users/${user}/alerts`);
  }

  createAlert(alert) {
    const user = this.getUser();

    return this.instance.post('/api/es_ar/alerts', {
      ...alert,
      user
    });
  }

  editAlert(alert) {
    return this.instance.post(
      `/api/es_ar/alerts/${alert.id}/frequencies?frequency=${alert.frequency}`
    );
  }

  deleteAlert(alert) {
    return this.instance.delete(`/api/es_ar/alerts/${alert.id}`);
  }
}

export default new UsersRestClient();
