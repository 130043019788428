import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Hidden } from '@material-ui/core';
import AppLayout from '../../commons/AppLayout/AppLayout';

import Feed from './Feed';
import CarouselSection from './CarouselSection';

// Messages
import {
  WELCOME_SHOWROOM,
  WELCOME_REGULAR,
  PUBLISHED_REGULAR,
  PUBLISHED_SHOWROOM,
  PUBLISHED_PREMIUM
} from '../../commons/messages/messageTypes';
import PublishedRegular from '../../commons/messages/PublishedRegular';
import PublishedShowroom from '../../commons/messages/PublishedShowroom';
import PublishedPremium from '../../commons/messages/PublishedPremium';
import WelcomeRegular from '../../commons/messages/WelcomeRegular';
import WelcomeShowroom from '../../commons/messages/WelcomeShowroom';
import { getBanners } from '../../redux/actions/publicity';
import { BANNERS_RETRIEVED } from '../../redux/actions/types';
import RTVMarketingFlash from '../../commons/RTVMarketingFlash';
import SustainabilityMeter from '../../commons/SustainabilityMeter/SustainabilityMeter';

const styles = theme => ({
  rowHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  scrollSection: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  text1: {
    fontWeight: 700
  },
  text2: {
    fontStyle: 'italic'
  },
  text3: {
    fontWeight: 200
  },
  text4: {
    fontWeight: 500
  },
  asideContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '60px 0 0 0'
  }
});

const assignMessage = messageType => {
  switch (messageType) {
    case WELCOME_SHOWROOM:
      return WelcomeShowroom;
    case WELCOME_REGULAR:
      return WelcomeRegular;
    case PUBLISHED_REGULAR:
      return PublishedRegular;
    case PUBLISHED_SHOWROOM:
      return PublishedShowroom;
    case PUBLISHED_PREMIUM:
      return PublishedPremium;
    default:
      return PublishedRegular;
  }
};

class Home extends Component {
  constructor(props) {
    super();

    let notFound = props.location.state && props.location.state.notFound;
    if (!notFound && props.notFound) {
      notFound = true;
    }

    this.state = {
      banners: [],
      notFound,
      appLayoutOptions: props.location.state
        ? {
            title: props.location.state.title,
            subtitle: props.location.state.subtitle,
            productTitle: props.location.state.producttitle,
            productPath: props.location.state.productPath,
            message: props.location.state.messageText
              ? props.location.state.messageText
              : assignMessage(props.location.state.message)
          }
        : {}
    };
  }

  componentDidMount() {
    this.props.getBanners();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bannersStatus !== this.props.bannersStatus) {
      if (this.props.bannersStatus === BANNERS_RETRIEVED) {
        this.setState({ banners: this.props.banners });
      }
    }
  }

  render() {
    const { appLayoutOptions } = this.state;

    return (
      <AppLayout
        title={appLayoutOptions.title}
        subtitle={appLayoutOptions.subtitle}
        message={appLayoutOptions.message}
        publishedProduct={{
          title: appLayoutOptions.productTitle,
          path: appLayoutOptions.productPath
        }}
        footer={!this.state.hasMoreCarousels}
        banners={this.state.banners}
        notFound={this.state.notFound}
      >
        <RTVMarketingFlash
          location="LIVE_GENERAL_LOCATIONS"
          containerProps={{ style: { margin: '16px 42px' } }}
          liveEnabled
        />
        <div
          style={{
            display: 'flex'
          }}
        >
          <div className={this.props.classes.scrollSection}>
            <CarouselSection user={this.props.user?.id} />
          </div>
          <div className={this.props.classes.asideContainer}>
            <Hidden smDown>
              <SustainabilityMeter />
              <Feed />
            </Hidden>
          </div>
        </div>
      </AppLayout>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  banners: state.publicity.banners,
  bannersStatus: state.publicity.status
});

const mapDispatchToProps = dispatch => ({
  getBanners: () => dispatch(getBanners())
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))
);
