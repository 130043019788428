import axiosInstance from './axiosConfig';
import MercadopagoSingleton from './MercadopagoSingleton';

class Mercadopago {
  mp;

  constructor() {
    this.mp = MercadopagoSingleton;
  }

  getFilds() {
    return this.mp.fields;
  }

  getPaymentOptions() {
    // Will give you the payment options that are
    // payment_type_id=ticket
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.getAllPaymentOptions();
        resolve(
          response.filter(
            option =>
              option.payment_type_id === 'ticket' ||
              option.payment_type_id === 'atm'
          )
        );
      } catch (error) {
        reject({ status: error.status });
      }
    });
  }

  async getAllPaymentOptions() {
    const response = await axiosInstance.get(
      '/api/es_ar/financial/meli/payment/methods'
    );
    return response.data;
  }

  getPaymentMethod(cardNumber) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.mp.getPaymentMethods({ bin: cardNumber });
        const results = response.results;
        // Workaround for Mastercard broken image from Mercadopago
        if (
          results.length > 0 &&
          results[0] &&
          results[0].secure_thumbnail &&
          results[0].secure_thumbnail ===
            'https://www.mercadopago.com/org-img/MPmaster/API/logos/master.gif'
        ) {
          results[0].secure_thumbnail =
            'https://www.mercadopago.com/org-img/MP3/API/logos/master.gif';
        }
        resolve(results);
      } catch (error) {
        return reject({ status: error.status });
      }
    });
  }

  getIdentificationTypes() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.mp.getIdentificationTypes();
        resolve(response);
      } catch (error) {
        return reject({ status: error.status });
      }
    });
  }

  createToken(cardForm) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.mp.createCardToken(cardForm);
        resolve(response);
      } catch (error) {
        return reject({ error });
      }
    });
  }

  getInstallments(bin, value, order, cardOptions) {
    const { gateway = true, aggregator = true } = cardOptions;

    return axiosInstance
      .post('/api/es_ar/financials/orders/installments', {
        bin,
        value,
        order
      })
      .then(({ data: response }) => {
        const installments = response
          .sort((a, b) => {
            if (a.processing_mode === 'gateway') {
              return -1;
            }
            if (b.processing_mode === 'gateway') {
              return 1;
            }
            return 0;
          })
          // We added the gateway and aggregatos fields to the cardOptions
          // if either gateway or aggregator is false, we should filter
          // those options to display.
          .filter(installmentGroup => {
            if (!gateway && installmentGroup.processing_mode === 'gateway') {
              return false;
            }
            if (
              !aggregator &&
              installmentGroup.processing_mode === 'aggregator'
            ) {
              return false;
            }
            return true;
          })
          .map(installmentGroup => {
            return {
              ...installmentGroup,
              payer_costs: installmentGroup.payer_costs.map(installment => {
                if (installment.installments === 13) {
                  return { ...installment, real_installments: 3 };
                }
                if (installment.installments === 16) {
                  return { ...installment, real_installments: 6 };
                }
                if (installment.installments === 7) {
                  return { ...installment, real_installments: 12 };
                }
                if (installment.installments === 8) {
                  return { ...installment, real_installments: 18 };
                }
                return {
                  ...installment,
                  real_installments: installment.installments
                };
              })
            };
          })
          .reduce((finalInstallments, currentInstallment) => {
            const installments = currentInstallment.payer_costs;
            installments.forEach(install => {
              if (
                !finalInstallments.find(
                  i => i.real_installments === install.real_installments
                )
              ) {
                finalInstallments.push(install);
              }
            });
            return finalInstallments;
          }, [])
          .sort((a, b) => {
            return a.real_installments - b.real_installments;
          });
        return installments;
      })
      .catch(e => {
        return { error: e };
      });
  }
}

export default new Mercadopago();
