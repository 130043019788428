import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as PremiumIcon } from '../images/Icons/Premium.svg';
import { ReactComponent as ShowroomIcon } from '../images/Icons/Showroom.svg';
import { ReactComponent as CelebritiesIcon } from '../images/Icons/Celebrities.svg';
import { ReactComponent as VipIcon } from '../images/Icons/Vip.svg';

const ROLES = {
  ROLE_PREMIUM: {
    icon: PremiumIcon,
    viewBox: '0 0 72 37'
  },
  ROLE_SHOWROOM: {
    icon: ShowroomIcon,
    viewBox: '0 0 48 31'
  },
  ROLE_SHOWROOMS: {
    icon: ShowroomIcon,
    viewBox: '0 0 48 31'
  },
  ROLE_VIP: {
    icon: VipIcon,
    viewBox: '0 0 54 27'
  },
  ROLE_CELEBRITY: {
    icon: CelebritiesIcon,
    viewBox: '0 0 50 43'
  },
  ROLE_CELEBRITIES: {
    icon: CelebritiesIcon,
    viewBox: '0 0 50 43'
  }
};

const RoleIcon = props => {
  if (!props.user && !props.roleVariant) {
    console.error('No role nor user object passed to `RoleIcon`!');
    return '';
  }

  let { roleVariant } = props;

  if (props.user) {
    roleVariant =
      props.user.type ||
      Object.keys(ROLES).find(r => props.user.roles.includes(r));
  }

  if (
    !roleVariant ||
    roleVariant === 'ROLE_MARKETPLACE' ||
    !ROLES[roleVariant]
  ) {
    return '';
  }

  return (
    <SvgIcon {...props} viewBox={ROLES[roleVariant].viewBox}>
      {React.createElement(ROLES[roleVariant].icon)}
    </SvgIcon>
  );
};

export default RoleIcon;
