import React, { useEffect, useState } from 'react';
import { Link as NavLink } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  SvgIcon
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import axiosInstance from '../../http/axiosConfig';
import userOnRTV from '../../images/userOnRTV.jpg';
import { ReactComponent as XCircle } from '../../images/Icons/x-circle.svg';

const styles = theme => ({
  dialog: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },
  message: {
    textAlign: 'center',
    padding: theme.spacing(4, 6, 4, 6)
  },
  header: {
    width: '350px',
    height: '400px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  image: {
    width: '100%',
    height: '100%'
  },
  mutedText: {
    padding: theme.spacing(2, 0, 2, 0),
    color: theme.palette.text.disabled
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  }
});

const RTVRegisterPopup = ({ open, onClose, classes }) => {
  const [campaignInfo, setCampaignInfo] = useState({
    amount: 500,
    minAmount: 5000
  });

  useEffect(() => {
    axiosInstance.get('/api/es_ar/campaign/welcome').then(response => {
      if (!response.data) {
        return;
      }
      setCampaignInfo({
        amount: response.data.type_parameter,
        minAmount: response.data.order_min_cost
      });
    });
  }, []);

  return (
    <Dialog style={{ padding: 0 }} open={open} onClose={onClose}>
      <DialogContent style={{ padding: 0 }}>
        <div className={classes.dialog}>
          <SvgIcon className={classes.closeIcon} onClick={onClose}>
            <XCircle />
          </SvgIcon>
          <div className={classes.header}>
            <img
              className={classes.image}
              src={userOnRTV}
              alt="cazadora popup"
            />
          </div>
          <div className={classes.message}>
            <Typography
              variant="h4"
              color="primary"
              style={{ fontWeight: 500 }}
            >
              Te Regalamos
            </Typography>
            <Typography
              variant="h4"
              color="primary"
              style={{ fontWeight: 800 }}
            >
              ${campaignInfo.amount}
            </Typography>
            <Typography variant="h6">En tu primera compra</Typography>
            <Typography variant="body2" className={classes.mutedText}>
              Válido para compras mayores a ${campaignInfo.minAmount}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={onClose}
              component={NavLink}
              to="/signup"
            >
              REGISTRARME
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(RTVRegisterPopup);
