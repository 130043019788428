import React, { useState, useEffect } from 'react';
import {
  List,
  WindowScroller,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache
} from 'react-virtualized';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axiosInstance from '../../http/axiosConfig';

import Carousel from './Carousel';
import LoadingCarousel from './LoadingCarousel';
import DresserCarousel from './DresserCarousel';

const CarouselSection = ({ user }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const [loadingCarousels, setLoadingCarousels] = useState(true);
  const [carousels, setCarousels] = useState([]);
  const [reloadCount, setReloadCount] = useState(0);

  const onCarouselFails = title => {
    setReloadCount(r => r + 1);
    setCarousels(carousels => carousels.filter(c => c.title !== title));
  };

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: matches ? 440 : 586,
    minHeight: matches ? 355 : 200,
  });

  useEffect(() => {
    let isSubscribed = true;
    axiosInstance
      .get(user ? `/api/es_ar/homes/${user}/user` : '/api/es_ar/home')
      .then(response => {
        // If the component is unmounted, we should not
        // try to update the component.
        if (!isSubscribed) {
          return;
        }
        // TODO(Toti): This version is right now only supporting carousels
        // of products, but we could add a new Carousel with support to show
        // user cards, but there's still no carousel implemented with other types.
        // The filter is added just in case it's added and prevent it to break the home.
        setCarousels(response.data);
        setLoadingCarousels(false);
      })
      .catch(() => {
        console.error('Error fetching carousels from the server');
      });
    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingCarousels) {
    return (
      <>
        <LoadingCarousel />
        <LoadingCarousel />
        <LoadingCarousel />
        <LoadingCarousel />
        <LoadingCarousel />
        <LoadingCarousel />
        <LoadingCarousel />
        <LoadingCarousel />
        <LoadingCarousel />
      </>
    );
  }

  const rowRenderer = ({
    key,
    index,
    isScrolling,
    parent,
    _isVisible,
    style
  }) => {
    const carousel = carousels[index];
    let Content = '';
    if (carousel.type === 'products') {
      Content = Carousel;
    } else if (carousel.type === 'users') {
      Content = DresserCarousel;
    }
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        <div style={style}>
          <Content
            title={carousel.title}
            source={carousel.source}
            target={carousel.target}
            isScrolling={isScrolling}
            onCarouselFails={onCarouselFails}
          />
        </div>
      </CellMeasurer>
    );
  };

  return (
    <WindowScroller>
      {({ height, isScrolling, onChildScroll, scrollTop }) => (
        <AutoSizer disableHeight>
          {({ width }) => (
            <List
              key={reloadCount}
              autoHeight
              height={height}
              isScrolling={isScrolling}
              onScroll={onChildScroll}
              rowHeight={cache.rowHeight}
              rowCount={carousels.length}
              rowRenderer={rowRenderer}
              scrollTop={scrollTop}
              width={width}
              style={{ overflowY: 'visible' }}
              overscanRowCount={5}
              deferredMeasurementCache={cache}
            />
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  );
};

export default CarouselSection;
