import AbstractRestClient from '../AbstractRestClient';

class PublicityRestClient extends AbstractRestClient {
  getBanners() {
    return this.instance.get(
      '/api/es_ar/publicity/banners?onlyActive=true&onlyWeb=true'
    );
  }
}

export default new PublicityRestClient();
