import React, { Suspense } from 'react';
import { CircularProgress, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import store, { persistor, history } from './redux/store';
import theme from './theme';
import AppRouting from './AppRouting';
import RTVLoadingHanger from './commons/loading/RTVLoadingHanger';
import './App.scss';

const Loader = () => (
  <div
    style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <CircularProgress />
  </div>
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<Loader />}>
          <ConnectedRouter history={history}>
            <Suspense fallback={<RTVLoadingHanger fullScreen />}>
              <AppRouting />
            </Suspense>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
