import React, { useState, useEffect } from 'react';
import { push } from 'connected-react-router';
import { Link as NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Paper,
  Typography,
  SvgIcon,
  IconButton,
  CircularProgress,
  ClickAwayListener,
  Button,
  withWidth
} from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { addToCart } from '../redux/actions/user';
import { ReactComponent as MessageIcon } from '../images/Icons/message-circle.svg';
import { ReactComponent as HeartIcon } from '../images/Icons/heart.svg';
import { ReactComponent as ShareIcon } from '../images/Icons/share.svg';
import { ReactComponent as WhatsApp } from '../images/Icons/whatsapp-01.svg';
import { ReactComponent as Facebook } from '../images/Icons/facebook.svg';
import { ReactComponent as Twitter } from '../images/Icons/twitter.svg';
import { ReactComponent as Mail } from '../images/Icons/mail.svg';
import MailShareDialog from './dialogs/RTVMailShareDialog';
import numberWithCommas from '../utils/numberWithCommas';
import discountImage from '../utils/discountImage';
import normalizeString from '../utils/normalizeString';
import HeartRoto from '../images/Heart_Roto.svg';
import ProductImageFail from '../images/ProductImageFail.png';
import RTVFavouriteButton from './RTVFavouriteButton';
import RTVAvatar from './RTVAvatar';
import RoleIcon from './RoleIcon';
import { SHOPPING_CART_ADD_SUCCESSFULL } from '../redux/actions/types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: ({ viewOnly }) => ({
    padding: theme.spacing(1),
    textAlign: 'center',
    width: '270px',
    height: '526px',
    [theme.breakpoints.down('xs')]: {
      width: '179px',
      height: '375px'
    },
    pointerEvents: viewOnly && 'none'
  }),
  paper: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'stretch',
    alignItems: 'flex-start',
    padding: theme.spacing(1),
    width: '100%',
    height: '100%',
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    borderBottomLeftRadius: '3px',
    borderBottomRightRadius: '3px',
    '& > *': {
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    }
  },
  miniatureHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end'
  },
  headerTitle: {
    display: 'flex',
    flexFlow: 'column',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    height: '100%',
    margin: theme.spacing(1)
  },
  productTitle: {
    '& > *': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    marginBottom: '-4px',
    [theme.breakpoints.down('xs')]: {
      '& > *': {
        fontSize: '0.9rem'
      }
    }
  },
  avatar: {
    margin: theme.spacing(0, 1, 1, 1),
    width: theme.spacing(6.5),
    height: theme.spacing(6.5),
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      width: '40px',
      height: '40px'
    }
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      padding: theme.spacing(1)
    }
  },
  prices: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    '& > *': {
      padding: theme.spacing(0, 1, 0, 1)
    },
    [theme.breakpoints.down('xs')]: {
      '& > *': {
        padding: theme.spacing(0, 0.5, 0, 0.5),
        fontSize: '0.8rem'
      }
    }
  },
  socialSection: ({ viewOnly }) => {
    const style = {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center'
    };

    if (viewOnly) {
      return {
        ...style,
        pointerEvents: 'none'
      };
    }

    return style;
  },
  social: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      '& > *': {
        fontSize: '0.9rem'
      }
    }
  },
  socialIcon: {
    stroke: theme.palette.secondary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
      padding: theme.spacing(1)
    }
  },
  socialLinks: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexFlow: 'row'
  },
  socialLinkAvatar: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    cursor: 'pointer'
  },
  whiteIcon: {
    stroke: '#fff',
    '& path.st0': {
      stroke: '#fff'
    }
  },
  activeIcon: {
    fill: theme.palette.secondary.main,
    '& path': {
      fill: theme.palette.secondary.main,
      stroke: theme.palette.secondary.main
    }
  },
  striked: {
    textDecoration: 'line-through'
  },
  primaryPriceTag: {
    fontWeight: 800,
    fontSize: '1.3rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  },
  image: {
    position: 'relative',
    cursor: 'pointer',
    '& > img': {
      width: '100%'
    },
    '& > button': {
      display: 'none'
    },
    '@media (pointer: fine)': {
      '&:hover > button': {
        display: 'block'
      }
    }
  },
  purchaseButton: {
    position: 'absolute',
    width: '80%',
    opacity: '1',
    bottom: theme.spacing(2),
    padding: theme.spacing(0.5)
  },
  socialButton: {
    padding: 0,
    marginRight: theme.spacing(1),
    transition: 'all .2 ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)'
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: 0
    }
  },
  socialMenu: ({ shopView }) => ({
    backgroundColor: theme.palette.secondary.other,
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    borderBottomLeftRadius: '3px',
    borderBottomRightRadius: '3px',
    padding: theme.spacing(1),
    '& > *': {
      padding: theme.spacing(0.5)
    },
    position: shopView && 'absolute',
    zIndex: shopView && 1,
    width: shopView && '255px',
    [theme.breakpoints.down('xs')]: {
      width: shopView && '165px'
    }
  }),
  user: {
    color: '#000',
    textDecoration: 'none',
    fontWeight: 500
  },
  promotionImage: {
    position: 'absolute',
    width: '65px !important',
    height: '65px',
    right: '-6px',
    top: '-34.5px',
    [theme.breakpoints.down('xs')]: {
      height: '35px',
      width: '35px !important',
      right: '-4px',
      top: '-22.5px'
    }
  },
  sizes: {
    fontSize: '0.9rem',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0
    }
  },
  // eslint-disable-next-line camelcase
  price: ({ offer_price }) =>
    offer_price ? { textDecoration: 'line-through' } : {}
}));

const ProductMiniature = props => {
  const { t } = useTranslation();
  const [shareMenu, setShareMenu] = useState(false);
  const [mailString, setMailString] = useState('');
  const [mailDialog, setMailDialog] = useState(false);
  const [imageFallback, setImageFallback] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const { product } = props;

  const classes = useStyles(props);

  // Sharemenu effect
  useEffect(() => {
    if (props.togglePaddingHack) {
      props.togglePaddingHack(shareMenu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareMenu]);

  // mailString clear
  useEffect(() => {
    if (!mailDialog) {
      setMailString('');
    }
  }, [mailDialog]);

  // Cart addition check
  useEffect(() => {
    if (
      props.cartStatus === SHOPPING_CART_ADD_SUCCESSFULL &&
      props.productAdded === product.id
    ) {
      props.push('/checkout/carrito');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cartStatus, props.productAdded]);

  const toggleShareMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    setShareMenu(menu => !menu);
  };

  // If the product image fails to load
  // we set the image to be the fallback
  const handleImageError = () => {
    setImageFallback(true);
  };

  const mailDialogConfirm = () => {
    const mailQuote =
      'Miren mi nuevo producto publicado en RenovaTuVestidor! en ';

    window.open(
      `mailto:${mailString}?&body=${mailQuote} ${window.encodeURIComponent(
        `${process.env.REACT_APP_WEB_URL}/producto/id/${product.id}`
      )}`,
      '_blank'
    );
    setMailDialog(false);
  };

  const handlePurchase = e => {
    e.stopPropagation();
    e.preventDefault();
    if (disabledButton) {
      return;
    }
    setDisabledButton(true);
    let path = `/producto/id/${product.id}`;
    if (
      product.sub_sub_category &&
      product.sub_sub_category.sub_category &&
      product.sub_sub_category.sub_category.category
    ) {
      const category = normalizeString(
        product.sub_sub_category.sub_category.category.name
      );
      const subCategory = normalizeString(
        product.sub_sub_category.sub_category.name
      );
      const subSubCategory = normalizeString(product.sub_sub_category.name);
      path = `/producto/${category}/${subCategory}/${subSubCategory}/${product.id}`;
    }
    if (product.status !== 'STATUS_LIVE' || !props.isLoggedIn) {
      props.push(path);
      return;
    }
    props.addToCart(product.id);
  };

  let path = `/producto/id/${product.id}`;
  if (
    product.sub_sub_category &&
    product.sub_sub_category.sub_category &&
    product.sub_sub_category.sub_category.category
  ) {
    const category = normalizeString(
      product.sub_sub_category.sub_category.category.name
    );
    const subCategory = normalizeString(
      product.sub_sub_category.sub_category.name
    );
    const subSubCategory = normalizeString(product.sub_sub_category.name);
    path = `/producto/${category}/${subCategory}/${subSubCategory}/${product.id}`;
  }

  let avatarSrc = null;
  if (product.user.media) {
    avatarSrc =
      product.user.media.default_small || product.user.media.user_small;
  } else if (product.user.image) {
    avatarSrc =
      product.user.image.default_small || product.user.image.user_small;
  }

  let productImage = ProductImageFail;
  if (product.main_image && !imageFallback) {
    productImage =
      product.main_image.default_big || product.main_image.product_medium;
  } else if (imageFallback) {
    productImage = HeartRoto;
  }

  let shareToSingleQuote = 'Mirá+este+excelente+producto!';
  let shareToSeveralQuote = 'Miren+este+excelente+producto!';

  if (props.isLoggedIn) {
    if (props.loggedInUser.id === product.user.id) {
      shareToSeveralQuote =
        'Miren+el+producto+que+vendo+en+Renová+Tu+Vestidor!';
      shareToSingleQuote = 'Mirá+el+producto+que+vendo+en+Renová+Tu+Vestidor!';
    }
  }

  const offerPromotionImage = props.viewOnly ? null : discountImage(product);

  let primaryPrice = product.selling_price;

  if (!props.viewOnly) {
    if (product.user.promotion !== null) {
      if (product.user.promotion.type === 'TWENTY_PERCENT') {
        primaryPrice = (primaryPrice * 0.8).toFixed(1);
      }
      if (product.user.promotion.type === 'THIRTY_PERCENT') {
        primaryPrice = (primaryPrice * 0.7).toFixed(1);
      }
      if (product.user.promotion.type === 'FORTY_PERCENT') {
        primaryPrice = (primaryPrice * 0.6).toFixed(1);
      }
    } else if (product.selling_price !== product.final_price) {
      primaryPrice = product.final_price;
    }
  }

  const WHATSAPP_SHARE_URL =
    props.width === 'xs' || props.width === 'sm'
      ? 'whatsapp://'
      : 'https://wa.me/';

  return (
    <div className={classes.root}>
      <MailShareDialog
        open={mailDialog}
        mailString={mailString}
        setMailString={setMailString}
        onClose={() => setMailDialog(false)}
        onConfirm={mailDialogConfirm}
      />
      <Paper
        component={NavLink}
        to={path}
        target="_blank"
        className={classes.paper}
        style={{
          textDecoration: 'none',
          cursor: 'pointer',
          borderBottomRightRadius: shareMenu ? '0' : '3px',
          borderBottomLeftRadius: shareMenu ? '0' : '3px'
        }}
      >
        <div className={classes.miniatureHeader}>
          <RTVAvatar
            className={classes.avatar}
            src={avatarSrc}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              props.push({
                pathname: `/vestidor/${product.user.username}`,
                state: { ...product.user }
              });
            }}
          />
          <div className={classes.headerTitle}>
            <RoleIcon
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                props.push({
                  pathname: `/vestidor/${product.user.username}`,
                  state: { ...product.user }
                });
              }}
              user={product.user}
              style={
                props.width === 'xs'
                  ? { width: '0.9rem', height: '0.7rem' }
                  : {}
              }
            />
            <div>
              <Typography
                className={classes.user}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  props.push({
                    pathname: `/vestidor/${product.user.username}`,
                    state: { ...product.user }
                  });
                }}
              >
                {product.user.username.slice(
                  0,
                  props.width === 'xs' ? 10 : 20
                ) +
                  (product.user.username.length >
                  (props.width === 'xs' ? 10 : 20)
                    ? '…'
                    : '')}
              </Typography>
            </div>
          </div>
        </div>
        {/* eslint-disable-next-line */}
        <div className={classes.image}>
          {offerPromotionImage !== null && (
            <img
              src={offerPromotionImage}
              alt="cucarda"
              className={classes.promotionImage}
            />
          )}
          <LazyLoadImage
            style={
              props.width === 'xs'
                ? { width: '150px', height: '195px' }
                : { width: '239px', height: '310px' }
            }
            src={productImage}
            onError={handleImageError}
            alt={product.title}
            effect="blur"
          />
          {product.status !== 'STATUS_FINISHED' &&
            ((props.isLoggedIn &&
              props.user &&
              props.user.username !== product.user.username) ||
              !props.isLoggedIn) && (
              <Button
                variant="contained"
                color="primary"
                className={classes.purchaseButton}
                disabled={disabledButton}
                onClick={handlePurchase}
              >
                {product.status === 'STATUS_RESERVED' ? 'RESERVADO' : 'COMPRAR'}
              </Button>
            )}
        </div>
        <div className={classes.productTitle}>
          <Typography
            variant="h6"
            component="h3"
            style={{
              width: '100%',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}
          >
            {product.title}
          </Typography>
        </div>
        <div className={classes.prices}>
          {product.original_price !== product.selling_price && (
            <Typography className={classes.striked}>
              ${numberWithCommas(product.original_price)}
            </Typography>
          )}
          <Typography
            className={
              primaryPrice !== product.selling_price
                ? classes.striked
                : classes.primaryPriceTag
            }
          >
            ${numberWithCommas(product.selling_price)}
          </Typography>
          {primaryPrice !== product.selling_price && (
            <Typography className={classes.primaryPriceTag} color="primary">
              ${numberWithCommas(primaryPrice)}
            </Typography>
          )}
        </div>
        <Typography className={classes.sizes}>
          {t('size')} {product.size ? product.size.name : 'N/A'}
        </Typography>
        <div className={classes.socialSection}>
          <div className={classes.social}>
            <IconButton
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                props.push(`${path}#comments`);
              }}
              className={classes.socialButton}
            >
              <SvgIcon color="secondary" className={classes.socialIcon}>
                <MessageIcon />
              </SvgIcon>
            </IconButton>
            {product.count_comments}
          </div>
          <div className={classes.social}>
            <RTVFavouriteButton product={product}>
              {favProps => (
                <>
                  <IconButton
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      favProps.onClick(e);
                    }}
                    disabled={favProps.loading}
                    className={classes.socialButton}
                  >
                    {favProps.loading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <SvgIcon color="secondary" className={classes.socialIcon}>
                        <HeartIcon
                          className={
                            favProps.favourite ? classes.activeIcon : ''
                          }
                        />
                      </SvgIcon>
                    )}
                  </IconButton>
                  {favProps.justActed &&
                    favProps.favourite &&
                    product.count_favourite + 1}
                  {favProps.justActed &&
                    !favProps.favourite &&
                    product.count_favourite - 1}
                  {!favProps.justActed && product.count_favourite}
                </>
              )}
            </RTVFavouriteButton>
          </div>
          <div className={classes.social}>
            <IconButton
              onClick={toggleShareMenu}
              className={classes.socialButton}
            >
              <SvgIcon className={classes.socialIcon} color="secondary">
                <ShareIcon
                  className={
                    shareMenu ? classes.activeIcon : classes.socialIcon
                  }
                />
              </SvgIcon>
            </IconButton>
          </div>
        </div>
      </Paper>
      {shareMenu && (
        <ClickAwayListener onClickAway={toggleShareMenu}>
          <Paper className={classes.socialMenu}>
            <Typography fontSize="small" variant="body2">
              Compartir en:
            </Typography>
            <div className={classes.socialLinks}>
              <Avatar
                className={classes.socialLinkAvatar}
                component="a"
                target="_blank"
                href={`https://www.facebook.com/sharer/sharer.php?display=page&quote=${shareToSeveralQuote}&u=${window.encodeURIComponent(
                  `${process.env.REACT_APP_WEB_URL}${path}`
                )}`}
              >
                <SvgIcon fontSize="small">
                  <Facebook />
                </SvgIcon>
              </Avatar>
              <Avatar
                className={classes.socialLinkAvatar}
                component="a"
                target="_blank"
                href={`${WHATSAPP_SHARE_URL}send?text=${shareToSingleQuote}+${window.encodeURIComponent(
                  `${process.env.REACT_APP_WEB_URL}${path}`
                )}`}
                data-action="share/whatsapp/share"
              >
                <SvgIcon fontSize="small">
                  <WhatsApp className={classes.whiteIcon} />
                </SvgIcon>
              </Avatar>
              <Avatar
                className={classes.socialLinkAvatar}
                component="a"
                target="_blank"
                href={`https://twitter.com/intent/tweet?text=${shareToSeveralQuote}+${window.encodeURIComponent(
                  `${process.env.REACT_APP_WEB_URL}${path}`
                )}`}
              >
                <SvgIcon fontSize="small">
                  <Twitter />
                </SvgIcon>
              </Avatar>
              <Avatar
                onClick={() => setMailDialog(true)}
                className={classes.socialLinkAvatar}
              >
                <SvgIcon fontSize="small">
                  <Mail />
                </SvgIcon>
              </Avatar>
            </div>
          </Paper>
        </ClickAwayListener>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  productAdded: state.user.productAdded,
  cartStatus: state.user.status,
  isLoggedIn: state.auth.isLoggedIn,
  user: state.user.user,
  privateStats: state.user.privateStats,
  loggedInUser: state.auth.user
});

const mapDispatchToProps = dispatch => ({
  push: path => dispatch(push(path)),
  addToCart: product => dispatch(addToCart(product))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(ProductMiniature));
