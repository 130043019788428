import AbstractRestClient from '../AbstractRestClient';
import store from '../../redux/store';

class OrderRestClient extends AbstractRestClient {
  getUser = () => store.getState().auth.user.id;

  getUserObject = () => store.getState().auth.user;

  deleteOrder(order) {
    return this.instance.delete(`/api/es_ar/orders/${order}`);
  }

  deleteSellerOrder(order) {
    return this.instance.delete(`/api/es_ar/orders/${order}/seller`);
  }

  createOrder(order) {
    return this.instance.post('/api/es_ar/orders', order);
  }

  confirmOrder(order, orderConfirmType) {
    return this.instance.post(
      `/api/es_ar/orders/${order}/confirms`,
      orderConfirmType
    );
  }

  getShippingLabel(order, refund) {
    if (refund) {
      return this.instance.get(
        `/api/es_ar/shipping/orders/${order}/change/label`
      );
    }
    return this.instance.get(
      `/api/es_ar/shipping/orders/${order}/shipping/label`
    );
  }

  getSellerOrders(
    status = [],
    sort = 'DESC',
    search = '',
    offset = 0,
    pageSize = 20
  ) {
    const seller = this.getUser();

    const sString = status.reduce((string, s) => `${string}&status[]=${s}`, '');

    return this.instance.get(
      `/api/es_ar/orders/${seller}/seller?sort=${sort}&search=${search}&offset=${offset}&pageSize=${pageSize}${sString}`
    );
  }

  getBuyerOrders(
    status = [],
    sort = 'DESC',
    search = '',
    offset = 0,
    pageSize = 20
  ) {
    const buyer = this.getUser();

    const sString = status.reduce((string, s) => `${string}&status[]=${s}`, '');

    return this.instance.get(
      `/api/es_ar/orders/${buyer}/buyer?sort=${sort}&search=${search}&offset=${offset}&pageSize=${pageSize}${sString}`
    );
  }

  getOrder(order) {
    return this.instance.get(`/api/es_ar/orders/${order}`);
  }

  createShippingLabel(order, store = null, isChangingStore = false) {
    // If we get a 409 conflict in this endpoint
    // it could mean that there's a trouble with the
    // shipping set by the buyer of the order.
    const user = this.getUserObject();
    if (store) {
      return this.instance
        .post(`/api/es_ar/users/${user.id}/shippings/stores`, {
          store,
          firstName: user.first_name,
          lastName: user.last_name
        })
        .then(({ data }) => {
          if (isChangingStore) {
            return this.instance.patch(
              `/api/es_ar/shipping/orders/${order}/shipping`,
              {
                shippingAddress: data.id
              }
            );
          }
          return this.instance.post(
            `/api/es_ar/shipping/orders/${order}/shippings`,
            {
              shippingAddress: data.id
            }
          );
        });
    }
    return this.instance.post(`/api/es_ar/shipping/orders/${order}/shippings`);
  }

  getTracking(order) {
    return this.instance.get(
      `/api/es_ar/shipping/orders/${order}/shipping/tracking`
    );
  }

  refund(order, products) {
    const formbody = new FormData();
    const productsPayload = products.map(product => ({
      motive: product.motive,
      id: product.id,
      image_count: product.images.length,
      comment: product.comment
    }));
    const images = products.reduce((imgs, product) => {
      return [...imgs, ...(product.images ? product.images : [])];
    }, []);
    productsPayload.forEach(p => {
      formbody.append('purchased_product[]', JSON.stringify(p));
    });
    images.forEach((i, index) => {
      formbody.append('files[]', i, `purchased_product_image-${index}.jpg`);
    });

    return this.instance.post(`/api/es_ar/orders/${order}/refunds`, formbody, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  createChangeShipping(order, store) {
    return this.instance.post(`/api/es_ar/orders/${order}/changes`, {
      shippingAddress: store
    });
  }

  cancelChange(order) {
    return this.instance.delete(`/api/es_ar/orders/${order}/change`);
  }
}

export default new OrderRestClient();
