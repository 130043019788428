// Use instaces values or fallback to
// environment vars.
import axios from 'axios';
import { LOGOUT_REQUESTED } from '../redux/actions/types';
import store from '../redux/store';
import Reactotron from '../reactotronConfig';

// Create axios instance
const instance = axios.create({
  timeout: 30000
});

instance.defaults.baseURL =
  process.env.REACT_APP_BACKEND_URL || 'https://stage.renuevatucloset.com';

instance.interceptors.request.use(config => {
  if (
    store.getState().auth.isLoggedIn ||
    store.getState().auth.recoveringPassword ||
    store.getState().auth.disabledUser
  ) {
    config.headers.common.Authorization = `Bearer ${
      store.getState().auth.token
    }`;
  }
  config.headers['X-Rtv-Agent'] = 'WEB';
  return config;
});

instance.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    Reactotron.display({
      name: 'API', // label
      preview: response.config?.url,
      value: response, // can be object
      important: false
    });
    // Do something with response data
    return response;
  },
  error => {
    Reactotron.display({
      name: 'API', // label
      preview: error.config?.url,
      value: error,
      important: true
    });

    if (store.getState().auth.isLoggedIn) {
      if (error.response?.status === 401) {
        store.dispatch({ type: LOGOUT_REQUESTED, jwtError: true });
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
