import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { wrapHistory } from 'oaf-react-router';
import { routerMiddleware } from 'connected-react-router';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import promiseMiddleware from 'redux-promise';
import createRootReducer from './reducers';
import Reactotron from '../reactotronConfig';

export const history = createBrowserHistory();
wrapHistory(history);

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    'product',
    'publicity',
    'comments',
    'favourite',
    'shipping',
    'categories'
  ]
};

const combinedReducer = createRootReducer(history);

const persistedReducer = persistReducer(persistConfig, combinedReducer);

const store = applyMiddleware(
  routerMiddleware(history),
  thunk,
  promiseMiddleware
)(createStore)(persistedReducer, Reactotron.createEnhancer());

export default store;
export const persistor = persistStore(store);
