import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { useMediaQuery } from '@material-ui/core';

const useCarouselStyles = makeStyles(theme => ({
  skeletonCarousel: {
    width: '100%',
    height: '586px',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      height: '440px'
    }
  },
  skeletonHeader: {
    width: '400px',
    padding: theme.spacing(2)
  },
  miniatureSkeletons: {
    display: 'flex'
  },
  skeletonRect: {
    margin: theme.spacing(1),
    minWidth: '235px',
    minHeight: '460px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '163px',
      minHeight: '359px'
    }
  }
}));

const LoadingCarousel = ({ noAnimation, ...props }) => {
  const classes = useCarouselStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div className={classes.skeletonCarousel} {...props}>
      <div className={classes.rowHeader}>
        <Skeleton variant="rect" width={200} height={30} />
      </div>
      <div className={classes.miniatureSkeletons}>
        {
          /* eslint-disable react/no-array-index-key */
          new Array(8).fill(0).map((_, index) => (
            <Skeleton
              key={index}
              className={classes.skeletonRect}
              variant="rect"
              width={matches ? 163 : 235}
              height={matches ? 359 : 460}
              animation={noAnimation ? false : 'pulse'}
            />
          ))
          /* eslint-enable react/no-array-index-key */
        }
      </div>
    </div>
  );
};

export default LoadingCarousel;
