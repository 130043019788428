import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider,
  Button,
  Typography,
  SvgIcon,
  Avatar,
  Hidden
} from '@material-ui/core';
import { Link, NavLink } from 'react-router-dom';
import { Android, Apple } from '@material-ui/icons';

import { ReactComponent as ShieldIcon } from '../images/Icons/shield.svg';
import { ReactComponent as SmileIcon } from '../images/Icons/smile.svg';
import { ReactComponent as InstagramIcon } from '../images/Icons/instagram.svg';
import { ReactComponent as FacebookIcon } from '../images/Icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../images/Icons/twitter.svg';

import RenovaLogo from '../images/ar-707-x-200.png';
import RenovaLogoCl from '../images/logo-rtc.png';
import OcaLogo from '../images/oca-1.png';
import CorreoLogo from '../images/correo-arg-logo.png';
import ChilexpressLogo from '../images/chilexpress-logo.png';
import MercadoLogo from '../images/isologo-mercado-pago.png';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  footer: ({ noMargin }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    marginTop: noMargin ? 0 : theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column'
    },
    alignItems: 'stretch',
    justifyContent: 'space-evenly',
    backgroundColor: theme.palette.primary.background,
    '& > hr': {
      alignSelf: 'stretch',
      height: 'auto'
    }
  }),
  socialFooter: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-evenly',
    '& > *': {
      margin: theme.spacing(1)
    },
    '& > hr': {
      [theme.breakpoints.up('md')]: {
        alignSelf: 'stretch',
        height: 'auto'
      }
    },
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  footerSection: {
    display: 'flex',
    flex: 1,
    flexFlow: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 4, 0, 4),
    alignItems: 'center',
    textAlign: 'center',
    '& > button': {
      width: '100%'
    },
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  footerTitle: {
    fontWeight: 700
  },
  socialAvatar: {
    color: theme.palette.secondary.light,
    backgroundColor: '#fff'
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  footerLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.palette.secondary.main
  },
  faq: {
    margin: theme.spacing(0.5, 0, 0.5, 0)
  },
  footerButton: {
    width: '100%',
    '& > .MuiButton-label': {
      padding: 0
    }
  },
  legalFooter: {
    backgroundColor: '#000',
    color: '#fff',
    width: '100%',
    padding: '8px 125px 8px 125px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column'
    }
  },
  legalLogos: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 1, 0, 1)
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      '& > *': {
        margin: theme.spacing(2)
      }
    }
  },
  faqLink: {
    color: '#414042',
    textDecoration: 'none',
    display: 'block'
  }
}));

const Footer = ({ onlyLegalFooter, ...props }) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  return (
    <>
      {!onlyLegalFooter && (
        <>
          <div className={classes.footer}>
            <div className={classes.footerSection}>
              <Typography className={classes.footerTitle} color="primary">
                COMPRANDO
              </Typography>
              <div className={classes.faq}>
                <Typography
                  component="a"
                  href={
                    process.env.REACT_APP_COUNTRY === 'AR'
                      ? 'https://renovatuvestidor.zendesk.com/hc/es/articles/360056405332--C%C3%B3mo-puedo-pagar-mi-compra-'
                      : 'https://renuevatuclosetcl.zendesk.com/hc/es/articles/205148717--Con-qu%C3%A9-medios-de-pago-puedo-hacer-mi-compra-'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.faqLink}
                >
                  · Cómo son las formas de pago?
                </Typography>
                <Typography
                  component="a"
                  href={
                    process.env.REACT_APP_COUNTRY === 'AR'
                      ? 'https://renovatuvestidor.zendesk.com/hc/es/articles/360056405472--Cu%C3%A1les-son-los-medios-de-env%C3%ADo-'
                      : 'https://renuevatuclosetcl.zendesk.com/hc/es/articles/204464378--C%C3%B3mo-recibo-mis-compras-'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.faqLink}
                >
                  · Cómo son los medios de envío?
                </Typography>
                <Typography
                  component="a"
                  href={
                    process.env.REACT_APP_COUNTRY === 'AR'
                      ? 'https://renovatuvestidor.zendesk.com/hc/es/articles/360056865351--C%C3%B3mo-funcionan-los-cambios-Informaci%C3%B3n-General'
                      : 'https://renuevatuclosetcl.zendesk.com/hc/es/articles/204464408--C%C3%B3mo-funciona-la-nueva-metodolog%C3%ADa-de-cambios-'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.faqLink}
                >
                  · Cómo funcionan los cambios?
                </Typography>
                <Typography
                  component="a"
                  href={
                    process.env.REACT_APP_COUNTRY === 'AR'
                      ? 'https://renovatuvestidor.zendesk.com/hc/es/articles/360056406972--C%C3%B3mo-aplico-un-descuento-en-mi-compra-'
                      : 'https://renuevatuclosetcl.zendesk.com/hc/es/articles/208015458--C%C3%B3mo-funcionan-los-Cupones-C%C3%B3digo-de-Promos-'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.faqLink}
                >
                  · Cómo aplico un cupón de descuento?
                </Typography>
              </div>
              <Button
                className={classes.footerButton}
                component={Link}
                to="/como-funciona/como-comprar"
                variant="contained"
                color="primary"
              >
                CÓMO COMPRAR
              </Button>
              <div className={classes.subtitle}>
                <SvgIcon color="primary">
                  <ShieldIcon />
                </SvgIcon>
                <Typography style={{ fontStyle: 'italic' }} color="primary">
                  100% Segura
                </Typography>
              </div>
              <div className={classes.paragraphs}>
                <Typography>
                  Despacho en 5 días o te devolvemos tu dinero
                </Typography>
                <Typography>Todas tus compras tienen cambio</Typography>
              </div>
              {process.env.REACT_APP_COUNTRY === 'AR' && (
                <Button
                  className={classes.footerButton}
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    window.open(
                      'https://renovatuvestidor.zendesk.com/hc/es/articles/4731504442253'
                    )
                  }
                >
                  BOTÓN DE ARREPENTIMIENTO
                </Button>
              )}
            </div>
            <Hidden smDown>
              <Divider orientation="vertical" />
            </Hidden>
            <Hidden smUp>
              <Divider orientation="horizontal" />
            </Hidden>
            <div className={classes.footerSection}>
              <Typography className={classes.footerTitle} color="primary">
                VENDIENDO
              </Typography>
              <div className={classes.faq}>
                <Typography
                  component="a"
                  href={
                    process.env.REACT_APP_COUNTRY === 'AR'
                      ? 'https://renovatuvestidor.zendesk.com/hc/es/articles/360056554411--C%C3%B3mo-vender-'
                      : 'https://renuevatuclosetcl.zendesk.com/hc/es/articles/204464448--C%C3%B3mo-vendo-mis-prendas-en-RenuevaTuCloset-'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.faqLink}
                >
                  · Cómo publico un producto?
                </Typography>
                <Typography
                  component="a"
                  href={
                    process.env.REACT_APP_COUNTRY === 'AR'
                      ? 'https://renovatuvestidor.zendesk.com/hc/es/articles/360056340732--Cu%C3%A1l-es-la-comisi%C3%B3n-que-me-cobra-Renov%C3%A1-Tu-Vestidor-'
                      : 'https://renuevatuclosetcl.zendesk.com/hc/es/articles/205145167-Cu%C3%A1l-es-la-comisi%C3%B3n-que-me-cobra-Renueva-Tu-Closet-'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.faqLink}
                >
                  · Qué costo tiene publicar?
                </Typography>
                <Typography
                  component="a"
                  href={
                    process.env.REACT_APP_COUNTRY === 'AR'
                      ? 'https://renovatuvestidor.zendesk.com/hc/es/articles/360056113792--C%C3%B3mo-realizo-el-env%C3%ADo-de-una-venta-'
                      : 'https://renuevatuclosetcl.zendesk.com/hc/es/articles/204464548--C%C3%B3mo-realizo-el-env%C3%ADo-de-una-venta-'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.faqLink}
                >
                  · Cómo envío mis productos?
                </Typography>
                <Typography
                  component="a"
                  href={
                    process.env.REACT_APP_COUNTRY === 'AR'
                      ? 'https://renovatuvestidor.zendesk.com/hc/es/articles/360056800551--C%C3%B3mo-cobro-mis-ventas-'
                      : 'https://renuevatuclosetcl.zendesk.com/hc/es/articles/204464898--C%C3%B3mo-y-cuando-cobro-mis-ventas-'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.faqLink}
                >
                  · Cómo cobro mis ventas?
                </Typography>
              </div>
              <Button
                className={classes.footerButton}
                component={NavLink}
                to="/como-funciona/como-vender"
                variant="contained"
                color="primary"
              >
                CÓMO VENDER
              </Button>
              <div className={classes.subtitle}>
                <SvgIcon color="primary">
                  <SmileIcon />
                </SvgIcon>
                <Typography style={{ fontStyle: 'italic' }} color="primary">
                  {t('trustUs', { ns: 'home' })}
                </Typography>
              </div>
              <div className={classes.paragraphs}>
                <Typography>
                  Sin necesidad de encontrarte con tu compradora
                </Typography>
                <Typography>{t('collectYourSales', { ns: 'home' })}</Typography>
              </div>
            </div>
            <Hidden smDown>
              <Divider orientation="vertical" />
            </Hidden>
            <Hidden smUp>
              <Divider orientation="horizontal" />
            </Hidden>
            <div className={classes.footerSection} style={{ margin: 0 }}>
              <div className={classes.socialFooter}>
                <div className={classes.socialFooterSection}>
                  <Typography className={classes.footerTitle}>
                    {t('downloadTheApp', { ns: 'home' })}
                  </Typography>
                  <div
                    className={classes.socialFooter}
                    style={{ flexFlow: 'row' }}
                  >
                    <Avatar
                      className={classes.socialAvatar}
                      component="a"
                      href={
                        process.env.REACT_APP_COUNTRY === 'AR'
                          ? 'https://play.google.com/store/apps/details?id=com.renovatuvestidor.rtvapp'
                          : 'https://play.google.com/store/apps/details?id=com.renovatuvestidor.rtcapp'
                      }
                      target="_blank"
                    >
                      <Android />
                    </Avatar>
                    <Avatar
                      className={classes.socialAvatar}
                      component="a"
                      href={
                        process.env.REACT_APP_COUNTRY === 'AR'
                          ? 'https://apps.apple.com/app/id1110486509'
                          : 'https://apps.apple.com/app/id1141910756'
                      }
                      target="_blank"
                    >
                      <Apple />
                    </Avatar>
                  </div>
                </div>
                <Hidden smDown>
                  <Divider orientation="vertical" />
                </Hidden>
                <Hidden smUp>
                  <Divider orientation="horizontal" />
                </Hidden>
                <div className={classes.socialFooterSection}>
                  <Typography className={classes.footerTitle}>
                    {t('followUs', { ns: 'home' })}
                  </Typography>
                  <div
                    className={classes.socialFooter}
                    style={{ flexFlow: 'row' }}
                  >
                    <Avatar
                      className={classes.socialAvatar}
                      component="a"
                      href={
                        process.env.REACT_APP_COUNTRY === 'AR'
                          ? 'https://www.instagram.com/renovatuvestidor/'
                          : 'https://instagram.com/renuevatuclosetchile'
                      }
                      target="_blank"
                    >
                      <InstagramIcon />
                    </Avatar>
                    <Avatar
                      className={classes.socialAvatar}
                      component="a"
                      href={
                        process.env.REACT_APP_COUNTRY === 'AR'
                          ? 'https://www.facebook.com/Renovatuvestidor/'
                          : 'https://www.facebook.com/RenuevatuclosetChile'
                      }
                      target="_blank"
                    >
                      <FacebookIcon />
                    </Avatar>
                    <Avatar
                      className={classes.socialAvatar}
                      component="a"
                      href={
                        process.env.REACT_APP_COUNTRY === 'AR'
                          ? 'https://twitter.com/tuvestidor'
                          : 'https://twitter.com/tuclosetchile'
                      }
                      target="_blank"
                    >
                      <TwitterIcon />
                    </Avatar>
                  </div>
                </div>
              </div>
              <Typography>Te quedan más dudas?</Typography>
              <Button
                className={classes.footerButton}
                color="primary"
                variant="contained"
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_COUNTRY === 'AR'
                      ? 'https://renovatuvestidor.zendesk.com'
                      : 'https://renuevatuclosetcl.zendesk.com',
                    '_blank'
                  )
                }
              >
                PREGUNTAS FRECUENTES
              </Button>
              <Button
                className={classes.footerButton}
                style={{
                  backgroundColor: '#fff',
                  color: '#403f41'
                }}
                variant="contained"
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_COUNTRY === 'AR'
                      ? 'https://renovatuvestidor.zendesk.com/hc/es/requests/new'
                      : 'https://renuevatuclosetcl.zendesk.com/hc/es/requests/new',
                    '_blank'
                  )
                }
              >
                {t('contactUs', { ns: 'home' })}
              </Button>
              <Typography
                className={classes.footerLink}
                component={Link}
                to="/ayuda/t&c"
                target="_blank"
              >
                Términos y condiciones
              </Typography>
              <Typography
                className={classes.footerLink}
                component={Link}
                to="/ayuda/politicas"
                target="_blank"
              >
                Políticas de privacidad
              </Typography>
            </div>
          </div>
        </>
      )}
      <div className={classes.legalFooter}>
        <div className={classes.renova}>
          <img
            src={
              process.env.REACT_APP_COUNTRY === 'CL' ? RenovaLogoCl : RenovaLogo
            }
            alt="renova tu vestidor"
          />
        </div>
        <div className={classes.legalLogos}>
          {process.env.REACT_APP_COUNTRY === 'CL' && (
            <div
              role="presentation"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                window.open('https://www.chilexpress.cl/', '_blank')
              }
            >
              <img src={ChilexpressLogo} alt="Chilexpress" />
            </div>
          )}
          {process.env.REACT_APP_COUNTRY === 'AR' && (
            <>
              <div
                role="presentation"
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  window.open('https://www.correoargentino.com.ar/', '_blank')
                }
              >
                <img src={CorreoLogo} alt="correo argentino" />
              </div>
              <div
                role="presentation"
                style={{ cursor: 'pointer' }}
                onClick={() => window.open('https://www.oca.com.ar/', '_blank')}
              >
                <img src={OcaLogo} alt="OCA" />
              </div>
            </>
          )}
          <div
            role="presentation"
            style={{ cursor: 'pointer' }}
            onClick={() =>
              window.open('https://www.mercadopago.com.ar/home', '_blank')
            }
          >
            <img src={MercadoLogo} alt="Mercado Pago" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
