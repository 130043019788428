import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Info } from '@material-ui/icons';
import { Typography, Button } from '@material-ui/core';
import axiosInstance from '../../http/axiosConfig';

import welcomeStyles from './welcomeStyles';

const WelcomeRegular = ({ classes }) => {
  const [campaignInfo, setCampaignInfo] = useState({
    amount: 500,
    minAmount: 5000
  });
  const [requesting, setRequesting] = useState(false);

  useEffect(() => {
    setRequesting(true);
    axiosInstance
      .get('/api/es_ar/campaign/welcome')
      .then(response => {
        if (!response.data) {
          return;
        }
        setCampaignInfo({
          amount: response.data.type_parameter,
          minAmount: response.data.order_min_cost
        });
        setRequesting(false);
      })
      .catch(() => setRequesting(false));
  }, []);

  if (requesting) return <div />;

  return (
    <>
      <div className={classes.welcomeMessage}>
        <Typography
          color="primary"
          variant="h3"
          component="h3"
          style={{ fontWeight: 800, marginBottom: 0 }}
        >
          TE REGALAMOS ${campaignInfo.amount}
        </Typography>
        <Typography
          color="primary"
          variant="h3"
          component="h3"
          style={{ fontWeight: 500, marginTop: 0 }}
        >
          EN TU PRIMERA COMPRA
        </Typography>
        <Typography style={{ margin: 0 }}>
          * Mínimo de compra ${campaignInfo.minAmount}
        </Typography>
        <div className={classes.buttonGroup}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/productos"
          >
            QUIERO COMPRAR
          </Button>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/vender"
          >
            QUIERO VENDER
          </Button>
        </div>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to="/como-funciona"
          style={{
            padding: '4px',
            margin: 0,
            fontSize: '0.8rem',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Info style={{ marginRight: '8px' }} />
          Como funciona?
        </Button>
      </div>
    </>
  );
};

export default withStyles(welcomeStyles)(WelcomeRegular);
