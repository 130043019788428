import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography, List } from '@material-ui/core';
import {
  List as VirtualizedList,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader
} from 'react-virtualized';

import NotificationFactory from '../../commons/notifications/NotificationFactory';
import { fetchFeed } from '../../redux/actions/social';
import LoadingGif from '../../images/Loading.gif';
import {
  SOCIAL_FEED_RETRIEVED,
  SOCIAL_FEED_REQUESTED,
  SOCIAL_FEED_FAILED
} from '../../redux/actions/types';

const styles = theme => ({
  root: {
    height: 'calc(100vh - 16px - 80px)',
    position: 'sticky',
    width: '300px',
    background: '#fdfbfc',
    borderRadius: '25px',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    top: `calc(80px + ${theme.spacing(2)}px)`,

    display: 'flex',
    flexFlow: 'column',
    overflowY: 'auto'
  },
  feedContent: {
    display: 'flex',
    flexFlow: 'column'
  },
  list: {
    height: '100%'
  }
});

class Feed extends Component {
  constructor(props) {
    super(props);

    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
      minHeight: 90
    });

    this.state = {
      feed: [],
      hasMoreNotifications: true,
      page: 1,
      loading: false
    };
  }

  componentDidMount() {
    this.props.fetchFeed();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.socialStatus !== this.props.socialStatus) {
      if (this.props.socialStatus === SOCIAL_FEED_REQUESTED) {
        this.setState({ loading: true });
      }
      if (this.props.socialStatus === SOCIAL_FEED_RETRIEVED) {
        this.setState(state => ({
          ...state,
          loading: false,
          feed: [...state.feed, ...this.props.socialFeed],
          hasMoreNotifications: this.props.socialFeed.length === 15,
          page: state.page + 1
        }));
      }
      if (this.props.socialStatus === SOCIAL_FEED_FAILED) {
        this.setState({ loading: false });
      }
    }
  }

  handleLoadMore = () => {
    const PAGE_SIZE = 15;

    this.props.fetchFeed(PAGE_SIZE * this.state.page);
  };

  isRowLoaded = ({ index }) =>
    !this.state.hasMoreNotifications || index < this.state.feed.length;

  loadMoreRows = () => {
    if (this.state.loading) {
      return;
    }
    return this.handleLoadMore();
  };

  rowRenderer = ({ key, index, isScrolling, parent, isVisible, style }) => {
    const notification = this.state.feed[index];

    return (
      <CellMeasurer
        cache={this.cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {NotificationFactory(notification, 'feed', null, {
          style,
          isScrolling
        })}
      </CellMeasurer>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Typography style={{ fontWeight: 500 }} variant="h6">
          Novedades
        </Typography>
        {this.state.feed.length === 0 && this.state.loading && (
          <img
            style={{ width: '30px', height: '30px' }}
            src={LoadingGif}
            alt="loading"
          />
        )}

        <List className={classes.list}>
          <InfiniteLoader
            isRowLoaded={this.isRowLoaded}
            loadMoreRows={this.loadMoreRows}
            rowCount={
              this.state.hasMoreNotifications
                ? this.state.feed.length + 1
                : this.state.feed.length
            }
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ width, height }) => (
                  <VirtualizedList
                    ref={registerChild}
                    height={height}
                    rowHeight={this.cache.rowHeight}
                    rowCount={this.state.feed.length}
                    rowRenderer={this.rowRenderer}
                    onRowsRendered={onRowsRendered}
                    width={width}
                    style={{ overflow: 'visible' }}
                    deferredMeasurementCache={this.cache}
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </List>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  socialFeed: state.social.feed,
  socialStatus: state.social.status
});

const mapDispatchToProps = dispatch => ({
  fetchFeed: offset => dispatch(fetchFeed(offset))
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Feed)
);
