import { push } from 'connected-react-router';
import {
  CHANGE_EMAIL_REQUESTED,
  CHANGE_EMAIL_SUCCESSFULL,
  CHANGE_EMAIL_ERRORED,
  PIN_VERIFY_REQUESTED,
  PIN_VERIFY_SUCCESSFULL,
  PIN_VERIFY_FAILED,
  USERS_RECOMMENDS_REQUESTED,
  USERS_RECOMMENDS_RETRIEVED,
  USERS_RECOMMENDS_FAILED,
  USER_PRIVATE_STATS_REQUESTED,
  USER_PRIVATE_STATS_RETRIEVED,
  USER_PRIVATE_STATS_FAILED,
  USER_PUBLIC_STATS_REQUESTED,
  USER_PUBLIC_STATS_RETRIEVED,
  USER_PUBLIC_STATS_FAILED,
  USER_SELF_REQUESTED,
  USER_SELF_RETRIEVED,
  USER_SELF_FAILED,
  USER_REQUESTED,
  USER_RETRIEVED,
  USER_FAILED,
  USER_RECEIVED_REVIEWS_REQUESTED,
  USER_RECEIVED_REVIEWS_RETRIEVED,
  USER_RECEIVED_REVIEWS_FAILED,
  UPDATE_USER_PASSWORD_REQUESTED,
  UPDATE_USER_PASSWORD_SUCCESSFULL,
  UPDATE_USER_PASSWORD_ERRORED,
  SHOPPING_CART_ADD_REQUESTED,
  SHOPPING_CART_ADD_SUCCESSFULL,
  SHOPPING_CART_ADD_ERRORED,
  SHOPPING_CART_REMOVE_REQUESTED,
  SHOPPING_CART_REMOVE_SUCCESSFULL,
  SHOPPING_CART_REMOVE_ERRORED,
  SHOPPING_CART_REQUESTED,
  SHOPPING_CART_SUCCESSFULL,
  SHOPPING_CART_ERRORED,
  SHIPPING_ADDRESSES_REQUESTED,
  SHIPPING_ADDRESSES_RETRIEVED,
  SHIPPING_ADDRESSES_FAILED,
  SHIPPING_ADDRESS_CREATE_REQUESTED,
  SHIPPING_ADDRESS_CREATE_SUCCESSFULL,
  SHIPPING_ADDRESS_CREATE_FAILED,
  SHIPPING_ADDRESS_DELETE_REQUESTED,
  SHIPPING_ADDRESS_DELETE_SUCCESSFULL,
  SHIPPING_ADDRESS_DELETE_FAILED,
  SHIPPING_STORE_CREATE_REQUESTED,
  SHIPPING_STORE_CREATE_SUCCESSFULL,
  SHIPPING_STORE_CREATE_FAILED,
  DRESSERS_REQUESTED,
  DRESSERS_FAILED,
  DRESSERS_RETRIEVED,
  ACTIVATE_HOLIDAY_MODE_REQUESTED,
  ACTIVATE_HOLIDAY_MODE_SUCCESSFULL,
  ACTIVATE_HOLIDAY_MODE_ERRORED,
  DEACTIVATE_HOLIDAY_MODE_REQUESTED,
  DEACTIVATE_HOLIDAY_MODE_SUCCESSFULL,
  DEACTIVATE_HOLIDAY_MODE_ERRORED,
  ENABLE_USER_REQUESTED,
  ENABLE_USER_SUCCESSFULL,
  ENABLE_USER_ERRORED,
  DISABLE_USER_REQUESTED,
  DISABLE_USER_ERRORED,
  LOGOUT_REQUESTED,
  STATE_REGISTERING,
  CREATE_PROMOTION_REQUESTED,
  CREATE_PROMOTION_SUCCESSFULL,
  CREATE_PROMOTION_ERRORED,
  DELETE_PROMOTION_REQUESTED,
  DELETE_PROMOTION_SUCCESSFULL,
  DELETE_PROMOTION_ERRORED,
  ANSWER_REVIEW_REQUESTED,
  ANSWER_REVIEW_SUCCESSFULL,
  ANSWER_REVIEW_ERRORED,
  CALCULATE_DISCOUNT_REQUESTED,
  CALCULATE_DISCOUNT_SUCCESSFULL,
  CALCULATE_DISCOUNT_ERRORED,
  CREATE_REVIEW_REQUESTED,
  CREATE_REVIEW_SUCCESSFULL,
  CREATE_REVIEW_ERRORED,
  CAMPAIGNS_RETRIEVED,
  CAMPAIGNS_FAILED,
  CAMPAIGNS_REQUESTED,
  UTM_STORE,
  UTM_REMOVE,
  POST_UTM_REQUESTED,
  POST_UTM_SUCCESSFULL,
  POST_UTM_ERRORED,
  BUYER_OFFERS_REQUESTED,
  BUYER_OFFERS_RETRIEVED,
  BUYER_OFFERS_ERRORED,
  STATE_APP,
  HOLIDAY_CHECK_PERFORMED,
  USER_COUPONS_REQUESTED,
  USER_COUPONS_SUCCESSFULL,
  USER_COUPONS_ERRORED,
  USER_ALERTS_REQUESTED,
  USER_ALERTS_SUCCESSFULL,
  USER_ALERTS_ERRORED,
  CREATE_ALERT_REQUESTED,
  CREATE_ALERT_SUCCESSFULL,
  CREATE_ALERT_ERRORED,
  EDIT_ALERT_ERRORED,
  EDIT_ALERT_SUCCESSFULL,
  EDIT_ALERT_REQUESTED,
  DELETE_ALERT_REQUESTED,
  DELETE_ALERT_SUCCESSFULL,
  DELETE_ALERT_ERRORED
} from './types';
import { setAppState } from './app';
import UsersRestClient from '../../http/client/Users';
import store from '../store';

export const changeEmail = email => {
  const requested = () => ({ type: CHANGE_EMAIL_REQUESTED });

  const successfull = () => ({
    type: CHANGE_EMAIL_SUCCESSFULL,
    email
  });

  const errored = err => ({
    type: CHANGE_EMAIL_ERRORED,
    error: err
  });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.changeEmail(email)
      .then(response => {
        if (response.data.status === 'error') {
          dispatch(errored(response));
        } else {
          dispatch(successfull(response));
        }
      })
      .catch(err => dispatch(errored(err)));
  };
};

export const verifyPin = (user, pin) => {
  // If we try to verify a pin, and the pin has failed, we should
  // request a new pin, but also check if we got an error before
  // in case that the user verification passed and we were not
  // notified correctly.
  const requested = () => ({ type: PIN_VERIFY_REQUESTED });
  const successfull = () => ({ type: PIN_VERIFY_SUCCESSFULL });
  const errored = () => ({ type: PIN_VERIFY_FAILED });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.verifyPin(user, pin)
      .then(response => {
        if (response.data.status === 'error') {
          dispatch(errored(response));
        } else {
          dispatch(successfull(response));
        }
      })
      .catch(err => dispatch(errored(err)));
  };
};

export const fetchRecommends = () => {
  const requested = () => ({ type: USERS_RECOMMENDS_REQUESTED });
  const successfull = response => ({
    type: USERS_RECOMMENDS_RETRIEVED,
    recommends: response.data.items || response.data
  });
  const errored = error => ({ type: USERS_RECOMMENDS_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.fetchRecommends()
      .then(response => {
        if (response.data.status === 'error') {
          dispatch(errored(response));
        } else {
          dispatch(successfull(response));
        }
      })
      .catch(err => dispatch(errored(err)));
  };
};

export const fetchPrivateStats = () => {
  const requested = () => ({ type: USER_PRIVATE_STATS_REQUESTED });
  const successfull = response => ({
    type: USER_PRIVATE_STATS_RETRIEVED,
    stats: response.data
  });
  const errored = error => ({ type: USER_PRIVATE_STATS_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.fetchPrivateStats()
      .then(response => dispatch(successfull(response)))
      .catch(err => dispatch(errored(err)));
  };
};

export const fetchPublicStats = user => {
  const requested = () => ({ type: USER_PUBLIC_STATS_REQUESTED, user });
  const successfull = response => ({
    type: USER_PUBLIC_STATS_RETRIEVED,
    user,
    stats: response.data
  });
  const errored = error => ({ type: USER_PUBLIC_STATS_FAILED, error, user });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.fetchPublicStats(user)
      .then(response => dispatch(successfull(response)))
      .catch(err => dispatch(errored(err)));
  };
};

export const fetchSelf = () => {
  const requested = () => ({ type: USER_SELF_REQUESTED });
  const successfull = response => ({
    type: USER_SELF_RETRIEVED,
    user: response.data
  });
  const errored = error => ({ type: USER_SELF_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.fetchUser(store.getState().auth.user.id)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const fetchUser = (id, idType = 'id') => {
  const requested = () => ({ type: USER_REQUESTED, user: id, idType });
  const successfull = response => ({
    type: USER_RETRIEVED,
    user: response.data,
    requestingUser: id
  });
  const errored = error => ({ type: USER_FAILED, error, user: id });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.fetchUser(id, idType)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const fetchReviews = (user, page = 1, pageSize = 10) => {
  const requested = () => ({ type: USER_RECEIVED_REVIEWS_REQUESTED, user });
  const successfull = response => ({
    type: USER_RECEIVED_REVIEWS_RETRIEVED,
    reviews: response.data.items || response.data,
    user
  });
  const errored = error => ({
    type: USER_RECEIVED_REVIEWS_FAILED,
    error,
    user
  });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.fetchReviews(user, page, pageSize)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const changePassword = password => {
  const requested = () => ({ type: UPDATE_USER_PASSWORD_REQUESTED });
  const successfull = () => ({ type: UPDATE_USER_PASSWORD_SUCCESSFULL });
  const errored = error => ({ type: UPDATE_USER_PASSWORD_ERRORED, error });

  return dispatch => {
    dispatch(requested());
    return UsersRestClient.updatePassword(password)
      .then(() => dispatch(successfull()))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getCart = () => {
  const requested = () => ({ type: SHOPPING_CART_REQUESTED });
  const successfull = response => ({
    type: SHOPPING_CART_SUCCESSFULL,
    shoppingCart: response.data
  });
  const errored = error => ({ type: SHOPPING_CART_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.getCart()
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const addToCart = product => {
  const requested = () => ({ type: SHOPPING_CART_ADD_REQUESTED });
  const successfull = response => ({
    type: SHOPPING_CART_ADD_SUCCESSFULL,
    shoppingCart: response.data,
    productAdded: product
  });
  const errored = error => ({ type: SHOPPING_CART_ADD_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.addToCart(product)
      .then(response => {
        dispatch(successfull(response));
      })
      .catch(error => dispatch(errored(error.response)));
  };
};

export const removeFromCart = product => {
  const requested = () => ({ type: SHOPPING_CART_REMOVE_REQUESTED });
  const successfull = response => ({
    type: SHOPPING_CART_REMOVE_SUCCESSFULL,
    shoppingCart: response.data
  });
  const errored = error => ({ type: SHOPPING_CART_REMOVE_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.removeFromCart(product)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const fetchShippingAddresses = seller => {
  const requested = () => ({ type: SHIPPING_ADDRESSES_REQUESTED });
  const successfull = response => ({
    type: SHIPPING_ADDRESSES_RETRIEVED,
    shippingAddresses: response.data
  });
  const errored = error => ({ type: SHIPPING_ADDRESSES_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.fetchAddresses(seller)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const createShippingAddress = address => {
  const requested = () => ({ type: SHIPPING_ADDRESS_CREATE_REQUESTED });
  const successfull = response => ({
    type: SHIPPING_ADDRESS_CREATE_SUCCESSFULL,
    shippingAddress: response.data
  });
  const errored = error => ({ type: SHIPPING_ADDRESS_CREATE_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.createShippingAddress(address)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const deleteShippingAddress = address => {
  const requested = () => ({ type: SHIPPING_ADDRESS_DELETE_REQUESTED });
  const successfull = () => ({
    type: SHIPPING_ADDRESS_DELETE_SUCCESSFULL,
    shippingAddress: address
  });
  const errored = error => ({ type: SHIPPING_ADDRESS_DELETE_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.deleteShippingAddress(address)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const createShippingStore = (shippingStore, isChange = false) => {
  const requested = () => ({ type: SHIPPING_STORE_CREATE_REQUESTED });
  const successfull = response => ({
    type: SHIPPING_STORE_CREATE_SUCCESSFULL,
    shippingStore: response.data
  });
  const errored = error => ({ type: SHIPPING_STORE_CREATE_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.createShippingStore(shippingStore, isChange)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getDressers = (query, page, pageSize) => {
  const requested = () => ({ type: DRESSERS_REQUESTED });
  const successfull = response => ({
    type: DRESSERS_RETRIEVED,
    dressersResponse: response.data
  });
  const errored = error => ({ type: DRESSERS_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.getDressers(query, page, pageSize)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const activateHolidayMode = () => {
  const requested = () => ({ type: ACTIVATE_HOLIDAY_MODE_REQUESTED });
  const successfull = () => ({
    type: ACTIVATE_HOLIDAY_MODE_SUCCESSFULL
  });
  const errored = error => ({ type: ACTIVATE_HOLIDAY_MODE_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.activateHolidayMode()
      .then(() => dispatch(successfull()))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const deactivateHolidayMode = () => {
  const requested = () => ({ type: DEACTIVATE_HOLIDAY_MODE_REQUESTED });
  const successfull = () => ({
    type: DEACTIVATE_HOLIDAY_MODE_SUCCESSFULL
  });
  const errored = error => ({ type: DEACTIVATE_HOLIDAY_MODE_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.deactivateHolidayMode()
      .then(() => dispatch(successfull()))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const enableUser = () => {
  const requested = () => ({ type: ENABLE_USER_REQUESTED });
  const successfull = () => ({
    type: ENABLE_USER_SUCCESSFULL
  });
  const errored = error => ({ type: ENABLE_USER_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.enableUser()
      .then(() => {
        dispatch(successfull());
        dispatch(setAppState(STATE_APP));
        const userReducer = store.getState().user;
        if (userReducer.localStats && userReducer.localStats.utm !== null) {
          dispatch(postUTM(userReducer.localStats.utm));
        }
        dispatch(push('/'));
      })
      .catch(error => dispatch(errored(error.response)));
  };
};

export const disableUser = () => {
  const requested = () => ({ type: DISABLE_USER_REQUESTED });
  const successfull = () => ({
    type: LOGOUT_REQUESTED
  });
  const errored = error => ({ type: DISABLE_USER_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.disableUser()
      .then(() => {
        localStorage.removeItem('persist:root');
        dispatch(successfull());
        dispatch(setAppState(STATE_REGISTERING));
        dispatch(push('/home'));
      })
      .catch(error => dispatch(errored(error.response)));
  };
};

export const createPromotion = promotion => {
  const requested = () => ({ type: CREATE_PROMOTION_REQUESTED });
  const successfull = response => ({
    type: CREATE_PROMOTION_SUCCESSFULL,
    promotion: response.data
  });
  const errored = error => ({ type: CREATE_PROMOTION_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.startPromotion(promotion)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const calculateDiscount = discount => {
  const requested = () => ({ type: CALCULATE_DISCOUNT_REQUESTED });
  const successfull = response => ({
    type: CALCULATE_DISCOUNT_SUCCESSFULL,
    discount: response.data
  });
  const errored = error => ({ type: CALCULATE_DISCOUNT_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.calculateDiscount(discount)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getCampaigns = products => {
  const requested = () => ({ type: CAMPAIGNS_REQUESTED });
  const successfull = response => ({
    type: CAMPAIGNS_RETRIEVED,
    campaigns: response.data
  });
  const errored = error => ({ type: CAMPAIGNS_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.getCampaigns(products)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getCoupons = () => {
  const requested = () => ({ type: USER_COUPONS_REQUESTED });
  const successfull = response => ({
    type: USER_COUPONS_SUCCESSFULL,
    coupons: response.data
  });
  const errored = error => ({ type: USER_COUPONS_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.getCoupons()
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const deletePromotion = promotion => {
  const requested = () => ({ type: DELETE_PROMOTION_REQUESTED });
  const successfull = () => ({
    type: DELETE_PROMOTION_SUCCESSFULL,
    promotion
  });
  const errored = error => ({ type: DELETE_PROMOTION_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.deletePromotion(promotion)
      .then(() => dispatch(successfull()))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const createReview = (order, review, stars, fromList = false) => {
  const requested = () => ({ type: CREATE_REVIEW_REQUESTED });
  const successfull = response => ({
    type: CREATE_REVIEW_SUCCESSFULL,
    order,
    review: response.data,
    // This will help us known if we should modify the reducer
    // so we can plug the recently created review on the order
    // on the list or on the `currentOrder`
    fromList
  });
  const errored = error => ({ type: CREATE_REVIEW_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.createReview(order, review, stars)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const answerReview = (order, review, answer) => {
  const requested = () => ({ type: ANSWER_REVIEW_REQUESTED });
  const successfull = response => ({
    type: ANSWER_REVIEW_SUCCESSFULL,
    order: response.data
  });
  const errored = error => ({ type: ANSWER_REVIEW_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.answerReview(order, review, answer)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const storeUTM = utm => dispatch => dispatch({ type: UTM_STORE, utm });

export const removeUTM = () => dispatch => dispatch({ type: UTM_REMOVE });

export const postUTM = utm => {
  const requested = () => ({ type: POST_UTM_REQUESTED });
  const successfull = () => ({ type: POST_UTM_SUCCESSFULL });
  const errored = error => ({ type: POST_UTM_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.postUTM(utm)
      .then(() => dispatch(successfull()))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getBuyerOffers = (page, pageSize) => {
  const requested = () => ({ type: BUYER_OFFERS_REQUESTED });
  const successful = response => ({
    type: BUYER_OFFERS_RETRIEVED,
    buyerOffers: response.data
  });
  const errored = error => ({
    type: BUYER_OFFERS_ERRORED,
    error
  });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.getBuyerOffers(page, pageSize)
      .then(response => dispatch(successful(response)))
      .catch(err => dispatch(errored(err.response)));
  };
};

export const checkHolidayDate = () => dispatch =>
  dispatch({ type: HOLIDAY_CHECK_PERFORMED });

export const getUserAlerts = () => {
  const requested = () => ({ type: USER_ALERTS_REQUESTED });
  const successfull = response => ({
    type: USER_ALERTS_SUCCESSFULL,
    alerts: response.data
  });
  const errored = error => ({ type: USER_ALERTS_ERRORED, error });

  return dispatch => {
    dispatch(requested());
    return UsersRestClient.getAlerts()
      .then(response => dispatch(successfull(response)))
      .catch(err => dispatch(errored(err.response)));
  };
};

export const createAlert = alert => {
  const requested = () => ({ type: CREATE_ALERT_REQUESTED });
  const successfull = response => ({
    type: CREATE_ALERT_SUCCESSFULL,
    alert: response.data
  });
  const errored = error => ({ type: CREATE_ALERT_ERRORED, error });

  return dispatch => {
    dispatch(requested());
    return UsersRestClient.createAlert(alert)
      .then(response => dispatch(successfull(response)))
      .catch(err => dispatch(errored(err.response)));
  };
};

export const editAlert = alert => {
  const requested = () => ({ type: EDIT_ALERT_REQUESTED });
  const successfull = response => ({
    type: EDIT_ALERT_SUCCESSFULL,
    alert: response.data
  });
  const errored = error => ({ type: EDIT_ALERT_ERRORED, error });

  return dispatch => {
    dispatch(requested());
    return UsersRestClient.editAlert(alert)
      .then(response => dispatch(successfull(response)))
      .catch(err => dispatch(errored(err.response)));
  };
};

export const deleteAlert = alert => {
  const requested = () => ({ type: DELETE_ALERT_REQUESTED });
  const successfull = alert => ({
    type: DELETE_ALERT_SUCCESSFULL,
    alert
  });
  const errored = error => ({ type: DELETE_ALERT_ERRORED, error });

  return dispatch => {
    dispatch(requested());
    return UsersRestClient.deleteAlert(alert)
      .then(() => dispatch(successfull(alert)))
      .catch(err => dispatch(errored(err.response)));
  };
};
