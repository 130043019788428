import React from 'react';
import ReactDOM from 'react-dom';
import './reactotronConfig';
import './index.scss';
import App from './App';
import 'moment/locale/es';
import 'moment-duration-format';
import './i18n/config';

let firebaseConfig = {};
if (process.env.REACT_APP_ENV === 'production') {
  firebaseConfig = require('./firebaseConfig-production.json');
} else if (process.env.REACT_APP_ENV === 'productioncl') {
  firebaseConfig = require('./firebaseConfig-productioncl.json');
}  else {
  firebaseConfig = require('./firebaseConfig-development.json');
}

ReactDOM.render(<App />, document.getElementById('root'));

// Initialize Firebase
window.firebase.initializeApp(firebaseConfig);
window.firebase.analytics();

// Register serviceWorker for firebase messaging (push notifications).
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js');
}
