import React from 'react';
import AppLayout from './AppLayout/AppLayout';
import { ReactComponent as HeartRoto } from '../images/Heart_Roto.svg';
import { Typography } from '@material-ui/core';

export default () => (
  <AppLayout noProfile noInfo noBar title="">
    <div
      style={{
        width: '100%',
        height: '80vh',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography
        color="primary"
        style={{
          fontWeight: 600,
          fontSize: '2rem',
          marginTop: '2rem',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <HeartRoto
          style={{ width: '2rem', height: '2rem', marginRight: '4px' }}
        />
        Hola!
      </Typography>
      <Typography
        style={{ fontWeight: 400, fontSize: '1.5rem', textAlign: 'center' }}
      >
        Estamos implementando cambios
        <br />
        para mejorar tu experiencia de renovarte
      </Typography>
      <Typography
        color="primary"
        style={{
          fontWeight: 400,
          fontSize: '1.5rem',
          textAlign: 'center',
          marginTop: '4rem'
        }}
      >
        Vuelve a entrar en un rato.
        <br />
        Nos vemos!
      </Typography>
    </div>
  </AppLayout>
);
