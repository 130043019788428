import store from '../../redux/store';
import AbstractRestClient from '../AbstractRestClient';

class AuthenticationRestClient extends AbstractRestClient {
  getUsername = () => store.getState().auth.user.username;

  login(username, password) {
    return this.instance.post('/api/es_ar/authentication/login', {
      username,
      password
    });
  }

  facebookLogin(token) {
    return this.instance.post('/api/es_ar/authentication/facebook', { token });
  }

  register(user) {
    return this.instance.post('/api/es_ar/authentication/registers', user);
  }

  identifierExist(identifier) {
    return this.instance.post('/api/es_ar/authentication/identifiers/exists', {
      identifier
    });
  }

  resendPin(identifier) {
    return this.instance.post('/api/es_ar/authentication/users/pins/resends', {
      identifier: identifier || this.getUsername()
    });
  }

  sendPinViaCall(identifier) {
    const _identifier = identifier || this.getUsername();
    return this.instance.post('/api/es_ar/authentication/users/pins/calls', {
      identifier: _identifier
    });
  }

  cancelRegistration() {
    const username = this.getUsername();
    return this.instance.post(`/api/es_ar/authentication/cancels`, {
      identifier: username
    });
  }

  pinOptions(identifier) {
    return this.instance.post(`/api/es_ar/authentication/logins/pins/options`, {
      identifier
    });
  }

  requestLoginPin(identifier, channel) {
    return this.instance.post(
      `/api/es_ar/authentication/logins/pins/requests`,
      { identifier, channel }
    );
  }

  pinLogin(identifier, pin) {
    return this.instance.post(`/api/es_ar/authentication/logins/pins`, {
      identifier,
      pin
    });
  }

  heartbeat() {
    return this.instance.get('/api/heartbeat');
  }
}

export default new AuthenticationRestClient();
