import React, { Component } from 'react';
import querystring from 'querystring';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { push } from 'connected-react-router';
import {
  STATE_REGISTERING,
  STATE_WELCOME,
  STATE_APP,
  USER_PRIVATE_STATS_RETRIEVED,
  ENABLE_USER_CANCELLED
} from './redux/actions/types';

import { setAppState as SET_APP_STATE, heartbeat } from './redux/actions/app';
import {
  fetchPrivateStats,
  fetchSelf,
  storeUTM,
  removeUTM,
  postUTM,
  checkHolidayDate
} from './redux/actions/user';
import { registerToken } from './redux/actions/communication';
import { getFavouriteList } from './redux/actions/favourite';
import { getFollowList } from './redux/actions/social';
import { logout } from './redux/actions/auth';
import RTVRegisterPopup from './commons/dialogs/RTVRegisterPopup';
import RTVSwUpdate from './commons/RTVSwUpdate';
import AuthRoute from './commons/AuthRoute';
import Analytics from './services/Analytics';
import Home from './screens/home/Home';

import MobileLoading from './screens/mobileLoading/MobileLoading';
import Maintenance from './commons/Maintenance';
import RTVRate from './commons/RTVRate';
import StorageWatcher from './StorageWatcher';
import MercadoPagoCredit from './screens/checkout/MercadoPagoCredit';
import { Button } from '@material-ui/core';
import AdornedButton from './commons/AdornedButton';
import OrderRestClient from './http/client/Order';
import OnLive from './screens/OnLive';

// Pages
const SignUp = React.lazy(() => import('./screens/join/signup/SignUp'));
const Register = React.lazy(() => import('./screens/join/register/Register'));
const VerifyEmail = React.lazy(() =>
  import('./screens/join/verifications/VerifyEmail')
);
const VerifyPhone = React.lazy(() =>
  import('./screens/join/verifications/VerifyPhone')
);
const ProfileSetup = React.lazy(() =>
  import('./screens/join/profileSetup/ProfileSetup')
);
const FollowSetup = React.lazy(() =>
  import('./screens/join/followSetup/FollowSetup')
);
const Sell = React.lazy(() => import('./screens/sell/Sell'));
const StoreSetup = React.lazy(() => import('./screens/store/StoreSetup'));
const Configuration = React.lazy(() =>
  import('./screens/profileConfiguration/Configuration')
);
const MyAccount = React.lazy(() => import('./screens/myAccount/MyAccount'));
const ShippingLabel = React.lazy(() =>
  import('./screens/shippingLabel/ShippingLabel')
);
const ChangeLabel = React.lazy(() =>
  import('./screens/shippingLabel/ChangeLabel')
);
const MyFavourites = React.lazy(() =>
  import('./screens/myFavourites/MyFavourites')
);
const HowItWorks = React.lazy(() => import('./screens/howItWorks/HowItWorks'));
const HowToBuy = React.lazy(() => import('./screens/howItWorks/HowToBuy'));
const HowToSell = React.lazy(() => import('./screens/howItWorks/HowToSell'));
const RTVDialog = React.lazy(() => import('./commons/dialogs/RTVDialog'));
const RecoverPassword = React.lazy(() =>
  import('./screens/join/recoverPassword/RecoverPassword')
);
const MyOffers = React.lazy(() => import('./screens/myOffers/MyOffers'));

const ProductsRouter = React.lazy(() =>
  import('./screens/products/ProductsRouter')
);
const ProductRouter = React.lazy(() =>
  import('./screens/products/ProductRouter')
);
const DresserRouter = React.lazy(() =>
  import('./screens/dresser/DresserRouter')
);
const DressersRouter = React.lazy(() =>
  import('./screens/dressers/DressersRouter')
);
const NotificationsRouting = React.lazy(() =>
  import('./screens/notifications/NotificationsRouting')
);
const SocialRouting = React.lazy(() =>
  import('./screens/social/SocialRouting')
);
const CheckoutRouting = React.lazy(() =>
  import('./screens/checkout/CheckoutRouting')
);
const SalesRouting = React.lazy(() => import('./screens/sales/SalesRouting'));
const PurchasesRouting = React.lazy(() =>
  import('./screens/purchases/PurchasesRouting')
);
const HelpRouting = React.lazy(() => import('./screens/help/HelpRouting'));
const Login = React.lazy(() => import('./screens/join/login/Login'));
const LoginRedirect = React.lazy(() => import('./screens/LoginRedirect'));

class AppRouting extends Component {
  constructor(props) {
    super();

    const {
      utm_medium: utmMedium,
      utm_source: utmSource,
      utm_campaign: utmCampaign
    } = querystring.decode(props.location.search.replace('?', ''));

    let utm = null;

    if (utmMedium || utmSource || utmCampaign) {
      utm = { medium: utmMedium, source: utmSource, campaign: utmCampaign };
    }

    this.state = {
      showPopup: false,
      requestingFollowers: false,
      requestingFavourites: false,
      pageChangeCheck: false,
      jwtFailed: false,
      utm,
      vacationModalOpen: false,
      rateModalOpen: false,
      orderToRate: null
    };
  }

  componentDidMount() {
    this.props.heartbeat();

    // The user tried to login with a disabled account and then tried reloading the page
    // before deciding to activate their account so they still would have their credentials
    // we want to clean them.
    if (this.props.disabledUser) {
      this.props.enableUserCancel();
    }

    let waitForZendesk = setInterval(() => {
      if (!window.onZendeskStart) {
        return;
      }

      let name = 'Anon';
      let email = 'Sin Email - No logueada';
      let acquired = false; // don't remember what this was, possible users that are buyers?
      if (this.props.isLoggedIn) {
        name = this.props.user.username;
        email = this.props.privateStats.email;
        acquired = false; // for now.
        window.zE.hide();
      }

      window.onZendeskStart(name, email, acquired, this.props.isLoggedIn);

      clearInterval(waitForZendesk);
    }, 100);

    const path = this.props.location.pathname + this.props.location.search;
    Analytics.pageView(path);

    if (this.props.appState === STATE_REGISTERING) {
      setTimeout(this.showPopup, 30000);
      if (this.state.utm !== null) {
        this.props.storeUTM(this.state.utm);
      }
    }

    if (this.props.isLoggedIn && this.props.appState === STATE_APP) {
      this.setupPushNotifications();
      this.props.fetchPrivateStats();
      this.props.fetchSelf();
      if (this.state.utm) {
        this.props.postUTM(this.state.utm);
      }
      this.setState({ pageChangeCheck: true });
    }

    if (this.props.recoveringPassword) {
      this.props.push('/recuperacion');
    }

    if (this.props.isLoggedIn) {
      this.props.fetchPrivateStats();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
      if (!this.props.isLoggedIn && this.props.appState !== STATE_REGISTERING) {
        this.props.setAppState(STATE_REGISTERING);
        if (this.props.jwtError) {
          this.setState({ jwtFailed: true }, () => {
            this.props.push('/login');
          });
        }
      }

      if (this.props.isLoggedIn) {
        this.setupPushNotifications();
      }

      if (this.props.isLoggedIn && window.onZendeskStart) {
        const name = this.props.user.username;
        const email = this.props.privateStats.email;
        const acquired = false; // for now.
        window.onZendeskStart(name, email, acquired, this.props.isLoggedIn);
      }

      if (!this.props.isLoggedIn) {
        const name = 'Anon';
        const email = 'Sin Email - No logueada';
        const acquired = false;
        window.onZendeskStart(name, email, acquired, this.props.isLoggedIn);
      }
    }

    // Make sure we are to start following people as soon as we have our user ready
    // on the follow setup page.
    if (
      prevProps.appState === STATE_REGISTERING &&
      this.props.appState === STATE_WELCOME
    ) {
      // eslint-disable-next-line max-len
      if (
        this.props.user.id &&
        !this.props.localStats.first_favourites_request &&
        !this.state.requestingFavourites
      ) {
        this.setState({ requestingFavourites: true }, () => {
          this.props.getFavouriteList(this.props.user.id);
        });
      }
      // eslint-disable-next-line max-len
      if (
        this.props.user.id &&
        !this.props.localStats.first_followings_request &&
        !this.state.requestingFollowers
      ) {
        this.setState({ requestingFollowers: true }, () => {
          this.props.getFollowList(this.props.user.id);
        });
      }
    }

    if (prevProps.status !== this.props.status) {
      if (!this.props.user) {
        return;
      }
      if (
        this.props.status === USER_PRIVATE_STATS_RETRIEVED &&
        this.props.privateStats.roles.includes('ROLE_DISABLED')
      ) {
        this.props.logout();
      }
      if (
        this.props.status === USER_PRIVATE_STATS_RETRIEVED &&
        this.state.pageChangeCheck &&
        !this.props.privateStats.roles.includes('ROLE_DISABLED')
      ) {
        this.setState({ pageChangeCheck: false }, () => {
          if (
            this.props.user?.id &&
            (this.props.localStats.products_favourite_last_updated <
              Date.parse(
                this.props.privateStats.products_favourite_last_updated
              ) ||
              !this.props.localStats.first_favourites_request) &&
            !this.state.requestingFavourites
          ) {
            this.setState({ requestingFavourites: true }, () => {
              this.props.getFavouriteList(this.props.user.id);
            });
          }
          if (
            this.props.user?.id &&
            (this.props.localStats.users_following_last_updated <
              Date.parse(
                this.props.privateStats.users_following_last_updated
              ) ||
              !this.props.localStats.first_followings_request) &&
            !this.state.requestingFollowers
          ) {
            this.setState({ requestingFollowers: true }, () => {
              this.props.getFollowList(this.props.user.id);
            });
          }
        });
        if (this.props.privateStats.roles.includes('ROLE_VACATION')) {
          // If hours between last holiday check and now is more than 24, opens modal
          if (
            this.props.localStats.lastHolidayCheck &&
            Math.round(
              Math.abs(new Date() - this.props.localStats.lastHolidayCheck) /
                36e5
            ) >= 24
          ) {
            this.setState({ vacationModalOpen: true });
          }
          this.props.checkHolidayDate();
        }
      }

      if (
        this.props.status === USER_PRIVATE_STATS_RETRIEVED &&
        this.props.privateStats.pending_review
      ) {
        if (!this.props.pendingReviewId) {
          this.fetchOrder(this.props.privateStats.pending_review.id);
          this.setState({ rateModalOpen: true });
        }

        if (
          this.props.pendingReviewId !==
          this.props.privateStats.pending_review.id
        ) {
          this.fetchOrder(this.props.privateStats.pending_review.id);
          this.setState({ rateModalOpen: true });
        }
      }
    }

    /**
     * Firebase Analytics.
     * We want to notify Firebase that we moved from the actual page.
     * reference: https://stackoverflow.com/questions/61120656/sending-react-router-page-views-to-firebase-analytics
     */
    if (
      prevProps.location.pathname !== this.props.location.pathname ||
      prevProps.location.search !== this.props.location.search
    ) {
      const path = this.props.location.pathname + this.props.location.search;
      Analytics.pageView(path);
    }

    if (this.props.isLoggedIn && this.props.appState === STATE_APP) {
      if (prevProps.location.pathname !== this.props.location.pathname) {
        this.props.fetchPrivateStats();
        this.props.fetchSelf();

        this.setState({ pageChangeCheck: true });
      }
    }
  }

  setupPushNotifications() {
    const messaging = window.firebase && window.firebase.messaging;
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration().then(registration => {
        if (typeof messaging === 'function' && messaging.isSupported()) {
          messaging()
            .getToken({ serviceWorkerRegistration: registration })
            .then(token => {
              if (this.props.pushToken && this.props.pushToken === token) {
                return;
              }
              this.props.registerToken(token);
            })
            .catch(e =>
              console.error(
                'Error al querer recibir token para push notifications',
                e
              )
            );

          messaging().onTokenRefresh(() => {
            messaging()
              .getToken({ serviceWorkerRegistration: registration })
              .then(token => {
                if (this.props.pushToken && this.props.pushToken === token) {
                  return;
                }
                this.props.registerToken(token);
              })
              .catch(e => {
                console.error(
                  'Error al cargar token para push notifications',
                  e
                );
              });
          });
        }
      });
    }
  }

  handlePopupClose = () => {
    this.setState({ showPopup: false });
  };

  showPopup = () => {
    const { pathname } = this.props.location;
    if (
      pathname !== '/register' &&
      pathname !== '/signup' &&
      pathname !== '/login' &&
      pathname !== '/mobile-communication' &&
      pathname !== '/recuperacion'
    ) {
      this.setState({ showPopup: true });
    }
  };

  fetchOrder = orderId => {
    OrderRestClient.getOrder(orderId).then(response =>
      this.setState({ orderToRate: response.data })
    );
  };

  render() {
    const REGISTER_ROUTES = (
      <>
        <RTVRegisterPopup
          open={this.state.showPopup}
          onClose={this.handlePopupClose}
        />
        <RTVSwUpdate />
        <Switch>
          <Route path="/mobile-communication" component={MobileLoading} />
          <Route path="/productos" component={ProductsRouter} />
          <Route path="/producto" component={ProductRouter} />
          <Route path="/home" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/signup" component={SignUp} />
          <Route path="/register" component={Register} />
          <Route path="/recuperacion" component={RecoverPassword} />
          <Route path="/vestidor" component={DresserRouter} />
          <Route path="/vestidores" component={DressersRouter} />
          <Route exact path="/como-funciona" component={HowItWorks} />
          <Route path="/como-funciona/como-comprar" component={HowToBuy} />
          <Route path="/como-funciona/como-vender" component={HowToSell} />
          <Route exact path="/live" component={OnLive} />
          <Route exact path="/" render={() => <Redirect to="/home" />} />
          {/* AuthRoutes */}
          <AuthRoute
            verification="phone"
            exact
            path="/vender"
            component={Sell}
          />
          <AuthRoute
            verification="phone"
            path="/configurar-perfil"
            component={ProfileSetup}
          />
          <AuthRoute
            verification="phone"
            path="/configurar-social"
            component={FollowSetup}
          />
          <AuthRoute
            verification="phone"
            path="/checkout"
            component={CheckoutRouting}
          />
          <AuthRoute
            verification="phone"
            path="/configuracion/:setting"
            component={Configuration}
          />
          <AuthRoute
            verification="phone"
            path="/configuracion/"
            component={Configuration}
          />
          <AuthRoute
            verification="phone"
            path="/mis-ventas"
            component={SalesRouting}
          />
          <AuthRoute
            verification="phone"
            path="/mis-compras"
            component={PurchasesRouting}
          />
          <AuthRoute
            verification="phone"
            path="/mi-cuenta"
            component={MyAccount}
          />
          <AuthRoute
            verification="phone"
            path="/social"
            component={SocialRouting}
          />
          <AuthRoute
            verification="phone"
            path="/notificaciones"
            component={NotificationsRouting}
          />
          <AuthRoute
            verification="phone"
            path="/shipping-label"
            component={ShippingLabel}
          />
          <AuthRoute
            verification="phone"
            path="/etiqueta-cambio"
            component={ChangeLabel}
          />
          <AuthRoute
            verification="phone"
            path="/mis-favoritos"
            component={MyFavourites}
          />
          <AuthRoute
            verification="phone"
            path="/mis-ofertas"
            component={MyOffers}
          />
          <Route verification="phone" path="/ayuda" component={HelpRouting} />
          <Route render={() => <Home notFound />} />
        </Switch>
      </>
    );

    const WELCOME_ROUTES = (
      <Switch>
        <Route path="/mobile-communication" component={MobileLoading} />
        <AuthRoute path="/validar-email" component={VerifyEmail} />
        <AuthRoute
          path="/validar-telefono"
          component={VerifyPhone}
          verification="email"
        />
        <AuthRoute
          verification="phone"
          path="/configurar-perfil"
          component={ProfileSetup}
        />
        <AuthRoute
          verification="phone"
          path="/configurar-social"
          component={FollowSetup}
        />
        <Route render={() => <Redirect to="/configurar-perfil" />} />
      </Switch>
    );

    const APP_ROUTES = (
      <>
        <RTVSwUpdate />
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/home" />} />
          <AuthRoute path="/validar-email" component={VerifyEmail} />
          <AuthRoute
            verification="email"
            path="/validar-telefono"
            component={VerifyPhone}
          />
          <AuthRoute verification="phone" path="/home" component={Home} />
          <AuthRoute
            verification="phone"
            exact
            path="/vender"
            component={Sell}
          />
          <AuthRoute
            verification="phone"
            exact
            path="/login-exitoso"
            component={LoginRedirect}
          />
          <AuthRoute
            verification="phone"
            path="/vender/store-setup"
            component={StoreSetup}
          />
          <AuthRoute
            verification="phone"
            path="/store-setup"
            component={StoreSetup}
          />
          <AuthRoute
            verification="phone"
            path="/producto"
            component={ProductRouter}
          />
          <AuthRoute
            verification="phone"
            path="/productos"
            component={ProductsRouter}
          />
          <AuthRoute
            verification="phone"
            path="/vestidor"
            component={DresserRouter}
          />
          <AuthRoute
            verification="phone"
            path="/vestidores"
            component={DressersRouter}
          />
          <AuthRoute
            verification="phone"
            path="/configurar-perfil"
            component={ProfileSetup}
          />
          <AuthRoute
            verification="phone"
            path="/configurar-social"
            component={FollowSetup}
          />
          <AuthRoute
            exact
            path={'/checkout/credito-mercadopago'}
            component={MercadoPagoCredit}
          />
          <AuthRoute
            verification="phone"
            path="/checkout"
            component={CheckoutRouting}
          />
          <AuthRoute
            verification="phone"
            path="/configuracion/:setting"
            component={Configuration}
          />
          <AuthRoute
            verification="phone"
            path="/configuracion/"
            component={Configuration}
          />
          <AuthRoute
            verification="phone"
            path="/mis-ventas"
            component={SalesRouting}
          />
          <AuthRoute
            verification="phone"
            path="/mis-compras"
            component={PurchasesRouting}
          />
          <AuthRoute
            verification="phone"
            path="/mi-cuenta"
            component={MyAccount}
          />
          <AuthRoute
            verification="phone"
            path="/social"
            component={SocialRouting}
          />
          <AuthRoute
            verification="phone"
            path="/notificaciones"
            component={NotificationsRouting}
          />
          <AuthRoute
            verification="phone"
            path="/shipping-label"
            component={ShippingLabel}
          />
          <AuthRoute
            verification="phone"
            path="/etiqueta-cambio"
            component={ChangeLabel}
          />
          <AuthRoute
            verification="phone"
            path="/mis-favoritos"
            component={MyFavourites}
          />
          <AuthRoute
            verification="phone"
            exact
            path="/como-funciona"
            component={HowItWorks}
          />
          <AuthRoute
            verification="phone"
            path="/como-funciona/como-comprar"
            component={HowToBuy}
          />
          <AuthRoute
            verification="phone"
            path="/como-funciona/como-vender"
            component={HowToSell}
          />
          <AuthRoute verification="phone" path="/live" component={OnLive} />
          <AuthRoute
            verification="phone"
            path="/mis-ofertas"
            component={MyOffers}
          />
          <AuthRoute
            verification="phone"
            path="/ayuda"
            component={HelpRouting}
          />
          <Route path="/mobile-communication" component={MobileLoading} />
          <Route render={() => <Home notFound />} />
        </Switch>
      </>
    );

    let router;

    switch (this.props.appState) {
      case STATE_REGISTERING:
        router = REGISTER_ROUTES;
        break;
      case STATE_WELCOME:
        router = WELCOME_ROUTES;
        break;
      case STATE_APP:
        router = APP_ROUTES;
        break;
      default:
        router = REGISTER_ROUTES;
    }

    return (
      <div>
        {!this.props.maintainance && (
          <RTVDialog
            open={this.state.jwtFailed}
            onClose={() => this.setState({ jwtFailed: false })}
            title="Tu sesion se ha vencido!"
            message="Logueate de nuevo!"
          />
        )}
        <RTVDialog
          open={this.state.vacationModalOpen}
          onClose={() => this.setState({ vacationModalOpen: false })}
          title="Tu vestidor está pausado."
          message="Queres activarlo y que tus productos vuelvan a estar a la venta?"
          actionSection={
            <>
              <AdornedButton
                variant="contained"
                color="primary"
                onClick={() => {
                  this.setState({ vacationModalOpen: false });
                  this.props.push('/mis-ventas/mis-productos');
                }}
              >
                Ok!
              </AdornedButton>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => this.setState({ vacationModalOpen: false })}
              >
                No, gracias!
              </Button>
            </>
          }
        />
        <Helmet>
          <title>Compra y venta de ropa - Renova tu vestidor</title>
          <meta
            property="og:title"
            content="Te invito a conocer Renová Tu Vestidor!"
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:description"
            content="Compra y venta de ropa online"
          />
          <meta
            property="og:image"
            content="https://cdn.renovatuvestidor.com/mktg/referidos_facebook.jpg"
          />
        </Helmet>
        <StorageWatcher />
        {!this.props.maintainance && router}
        {this.props.maintainance && (
          <Switch>
            <Route path="/mobile-communication" component={MobileLoading} />
            <Route render={() => <Maintenance />} />
          </Switch>
        )}
        {this.state.rateModalOpen &&
          this.state.orderToRate &&
          this.props.isLoggedIn && (
            <RTVRate order={this.state.orderToRate} showButton={false} />
          )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  isLoggedIn: state.auth.isLoggedIn,
  jwtError: state.auth.jwtError,
  status: state.user.status,
  appState: state.app.state,
  maintainance: state.app.maintainance,
  localStats: state.user.localStats,
  privateStats: state.user.privateStats,
  pushToken: state.communication.token,
  recoveringPassword: state.auth.recoveringPassword,
  disabledUser: state.auth.disabledUser,
  pendingReviewId: state.auth.pendingReviewId
});

const mapDispatchToProps = dispatch => ({
  setAppState: state => dispatch(SET_APP_STATE(state)),
  heartbeat: () => dispatch(heartbeat()),
  fetchPrivateStats: () => dispatch(fetchPrivateStats()),
  fetchSelf: () => dispatch(fetchSelf()),
  getFavouriteList: user => dispatch(getFavouriteList(user)),
  getFollowList: user => dispatch(getFollowList(user)),
  push: path => dispatch(push(path)),
  storeUTM: utm => dispatch(storeUTM(utm)),
  removeUTM: () => dispatch(removeUTM()),
  postUTM: utm => dispatch(postUTM(utm)),
  registerToken: token => dispatch(registerToken(token)),
  enableUserCancel: () => dispatch({ type: ENABLE_USER_CANCELLED }),
  checkHolidayDate: () => dispatch(checkHolidayDate()),
  logout: () => dispatch(logout())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppRouting)
);
