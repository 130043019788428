import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import normalizeString from '../../utils/normalizeString';
import RTVButton from '../RTVButton';

const styles = theme => ({
  categoryMenu: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  subCategoriesSection: {
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(1)
  },
  subSubCategoriesSection: {
    padding: theme.spacing(1),
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr',
    gridTemplateRows: 'repeat(9, 1fr)'
  },
  subCategory: {
    display: 'flex',
    alignItems: 'center'
  },
  subSubCategory: {
    cursor: 'pointer',
    padding: theme.spacing(1, 0.5, 1, 0.5),
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  showMoreButton: {
    padding: theme.spacing(1),
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '2rem'
  },
  buttonTitle: {
    fontWeight: 500
  }
});

class CategoryMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categorySelected: null
    };
  }

  handleCategorySelect = categorySelected => {
    this.setState({ categorySelected });
  };

  render() {
    const { classes, category, subCategories } = this.props;
    const { categorySelected } = this.state;

    return (
      <div className={classes.categoryMenu}>
        <div className={classes.subCategoriesSection}>
          {subCategories
            .filter(s => !s.disabled_shop)
            .map(subCategory => (
              <div key={subCategory.id} className={classes.subCategory}>
                <RTVButton
                  onClick={() =>
                    this.props.push(
                      `/productos/${category}/${normalizeString(
                        subCategory.name
                      )}`
                    )
                  }
                  onMouseEnter={() => this.handleCategorySelect(subCategory)}
                  onTouchStart={() => this.handleCategorySelect(subCategory)}
                  onTouchEnd={e => e.preventDefault()}
                  kind={`${category}${subCategory.name}`}
                  variant="wide"
                  selected={
                    categorySelected && categorySelected.id === subCategory.id
                  }
                >
                  {subCategory.name}
                </RTVButton>
                {/*
                  categorySelected
                  && categorySelected.id === subCategory.id
                  && (
                    <ChevronRight color="primary" />
                  )
                  */}
              </div>
            ))}
          <RTVButton
            variant="custom"
            style={{
              height: '75px',
              width: '250px'
            }}
            onClick={() => this.props.push(`/productos/${category}`)}
            onMouseEnter={() => this.handleCategorySelect(null)}
            custom={
              <div className={classes.showMoreButton}>
                <Typography className={classes.buttonTitle}>
                  VER TODO
                </Typography>
                <ChevronRight color="primary" />
              </div>
            }
          />
        </div>
        {categorySelected && (
          <div className={classes.subSubCategoriesSection}>
            {categorySelected.sub_sub_categories
              .filter(s => !s.disabled_shop)
              .map(subSubCategory => (
                <Typography
                  onClick={() =>
                    this.props.push(
                      `/productos/${category}/${normalizeString(
                        categorySelected.name
                      )}/${normalizeString(subSubCategory.name)}`
                    )
                  }
                  className={classes.subSubCategory}
                  key={subSubCategory.id}
                >
                  {subSubCategory.name}
                </Typography>
              ))}
            <Typography
              onClick={() =>
                this.props.push(
                  `/productos/${category}/${normalizeString(
                    categorySelected.name
                  )}`
                )
              }
              style={{ fontWeight: 800 }}
              className={classes.subSubCategory}
            >
              VER TODO
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  push: path => dispatch(push(path))
});

export default withStyles(styles)(
  connect(null, mapDispatchToProps)(CategoryMenu)
);
