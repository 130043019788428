import AbstractRestClient from '../AbstractRestClient';
import store from '../../redux/store';

class ProductRestClient extends AbstractRestClient {
  getUser = () => store.getState().auth.user.id;

  getProduct(id) {
    return this.instance.get(`/api/es_ar/products/${id}`);
  }

  productsFromUser(user, query = {}) {
    return this.instance.get(`/api/es_ar/products/${user}/user`, {
      params: { ...query }
    });
  }

  getProducts(params, page = 1, pageSize = 10) {
    // Avoid sending `sort` if it doesn't have a value to prevent
    // undefined behaviour on the request.
    if (params.sort && params.sort === '') {
      const { sort, ...restOfParams } = params;
      params = restOfParams;
    }
    // The followings argument now expects the user's id.
    if (
      params.followings &&
      (params.followings === 1 || params.followings === '')
    ) {
      params.followings = this.getUser();
    }
    return this.instance.get(
      `/api/es_ar/products?page=${page}&pageSize=${pageSize}`,
      { params }
    );
  }

  getMyProducts(query = {}) {
    const user = this.getUser();
    return this.productsFromUser(user, query);
  }

  getProductsPendingEdition(getProducts = 0, page = 1, pageSize = 10) {
    const user = this.getUser();
    return this.instance.get(
      `/api/es_ar/products/${user}/user/pending/edition?page=${page}&pageSize=${pageSize}&getProducts=${getProducts}`
    );
  }

  getOfferProducts(query = {}) {
    const user = this.getUser();
    query = { ...query, user };
    return this.instance.get('/api/es_ar/offers/seller', { ...query });
  }

  publishProduct(product) {
    let {
      images,
      category,
      condition,
      originalPrice,
      sellingPrice,
      color,
      tags,
      ...publishProduct
    } = product;
    let [mainImage, ...secondaryImage] = images;
    // Prevent no more than 6 images to go through.
    const secondaryImages = secondaryImage.slice(0, 6);

    publishProduct = {
      ...publishProduct,
      condition: condition.id,
      subSubCategory: category.subSubCategory.id,
      originalPrice: originalPrice.split('.').join(''),
      sellingPrice: sellingPrice.split('.').join('')
    };

    return new Promise((resolve, reject) => {
      if (images[0] === null) {
        return reject({
          status: 'error',
          response: { code: 400, message: 'Main image is null' }
        });
      } else {
        resolve();
      }
    }).then(() => {
      const formBody = new FormData();
      formBody.append('mainImage', mainImage, 'mainImage.jpg');

      secondaryImages.forEach((image, index) => {
        formBody.append(
          'secondaryImage[]',
          image,
          `secondaryImage-${index}.jpg`
        );
      });

      tags.forEach(tag => {
        formBody.append('tags[]', tag?.id || tag);
      });

      if (color) {
        formBody.append('color', color);
      }

      Object.keys(publishProduct).forEach(key => {
        formBody.append(key, publishProduct[key]);
      });

      return this.instance.post('/api/es_ar/products', formBody, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }, null);
  }

  editProduct(id, product) {
    let payload = {
      description: product.description,
      condition: product.condition.id,
      originalPrice: product.originalPrice.split('.').join(''),
      sellingPrice: product.sellingPrice.split('.').join(''),
      subSubCategory: product.category.subSubCategory.id,
      brand: product.brand,
      size: product.size,
      tags: product.tags.map(t => t.id)
    };
    if (product.color) {
      payload = { ...payload, color: product.color };
    }
    return this.instance.patch(`/api/es_ar/products/${id}`, payload);
  }

  editProductTagsAndColors(id, product) {
    return this.instance.patch(`/api/es_ar/products/${id}`, {
      tags: product.tags.map(t => t.id),
      color: product.color.id
    });
  }

  editProductImages(id, product) {
    let { images } = product;
    const formBody = new FormData();

    // Prevent no more than 6 images to go through.
    images = images.slice(0, 6);

    images.forEach((image, index) => {
      if (index === 0) {
        formBody.append('mainImage', image, 'mainImage.jpg');
      } else {
        formBody.append(
          `secondaryImage${index}`,
          image,
          `secondaryImage${index}.jpg`
        );
      }
    });

    return this.instance.post(`/api/es_ar/products/${id}/images`, formBody);
  }

  republish(product) {
    return this.instance.post(
      `/api/es_ar/purchaseds/${product}/products/republishes`
    );
  }

  sendOffer(product, offer) {
    return this.instance.post(`/api/es_ar/offers/${product}/products`, {
      value: offer
    });
  }

  pause(product) {
    return this.instance.post(`/api/es_ar/products/${product}/pauses`);
  }

  resumes(product) {
    return this.instance.post(`/api/es_ar/products/${product}/resumes`);
  }

  changePrice(product, newPrice) {
    return this.instance.post(`/api/es_ar/products/${product}/prices`, {
      value: newPrice
    });
  }

  changeStock(product, stock) {
    return this.instance.post(`/api/es_ar/products/${product}/stocks`, {
      value: stock
    });
  }

  republishOwned(product) {
    const user = this.getUser();

    return this.instance.post(
      `/api/es_ar/products/${product}/republishes/${user}`
    );
  }

  delete(product) {
    return this.instance.delete(`/api/es_ar/products/${product}`);
  }

  acceptOffer(product) {
    return this.instance.patch(`/api/es_ar/offers/${product}/product`);
  }

  counterOffer(product, counterOffer) {
    return this.instance.patch(`/api/es_ar/offers/${product}/counter/product`, {
      value: counterOffer
    });
  }

  rejectOffer(product) {
    return this.instance.delete(`/api/es_ar/offers/${product}/product`);
  }

  requestNotification(product) {
    return this.instance.post(`/api/es_ar/products/${product}/waitings`);
  }
}

export default new ProductRestClient();
