import {
  BANNERS_REQUESTED,
  BANNERS_RETRIEVED,
  BANNERS_FAILED,
  TEXT_BANNERS_REQUESTED,
  TEXT_BANNERS_RETRIEVED,
  TEXT_BANNERS_FAILED
} from './types';
import PublicityRestClient from '../../http/client/Publicity';

// eslint-disable-next-line import/prefer-default-export
export const getBanners = () => {
  const requested = () => ({ type: BANNERS_REQUESTED });
  const successfull = response => ({
    type: BANNERS_RETRIEVED,
    banners: response.data
  });
  const errored = error => ({ type: BANNERS_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return PublicityRestClient.getBanners()
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getTextBanners = () => {
  const requested = () => ({ type: TEXT_BANNERS_REQUESTED });
  const successfull = response => ({
    type: TEXT_BANNERS_RETRIEVED,
    banners: response.data.filter(b => b.type === 'TextBanner')
  });
  const errored = error => ({ type: TEXT_BANNERS_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return PublicityRestClient.getBanners()
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};
